// actions.ts
import { SessionKit } from "@wharfkit/session";
import { WebRenderer } from "@wharfkit/web-renderer";
import { WalletPluginAnchor } from "@wharfkit/wallet-plugin-anchor";
import { WalletPluginCloudWallet } from "@wharfkit/wallet-plugin-cloudwallet";
import { WalletPluginWombat } from "@wharfkit/wallet-plugin-wombat";
import { JsonRpc } from "eosjs";

import * as actionTypes from "./action-types";
import * as constants from "../../constant";
import { loadDataAction, getTokenBalance } from "./loader"; // Import loadDataAction and getTokenBalance from loader.ts

const {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  WORLD_SELECT,
  EXPLORATION_SUCCESS,
  EXPLORATION_ERROR,
  BUY_WORLD,
  SHOW_ALERT,
  SHOW_LOADING,
  HIDE_LOADING,
  CLAIM_STAKE,
  STAKE_SUCCESS,
  BUY_GOLD,
  GET_LEADERBOARD,
  GET_CONFIGS,
  STAKE_TOOL_SUCCESS,
  LEVELUP_SUCCESS,
  ASSET_STAKE_SUCCESS,
  CLAIM_EXPLORATION_SUCCESS,
  GET_EXPLORATIONS,
  LEVELUP_BOOST_SUCCESS,
  GET_TOURNAMENT_LIST,
  ENTER_TOURNAMENT_SUCCESS,
} = actionTypes;

const {
  chainEndpoint,
  GAME_CONTRACT,
  FEED_TOKEN_CONTRACT,
  SYSTEM_TOKEN_CONTRACT,
  TOKEN_CONTRACT,
  TOKEN_SYMBOL,
  GOLD_TOKEN_SYMBOL,
  SYSTEM_TOKEN_SYMBOL,
  FEED_TOKEN_SYMBOL,
} = constants;

const webRenderer = new WebRenderer();
console.log("chainEndpoint", chainEndpoint);

// Initialize SessionKit
const sessionKit = new SessionKit({
  appName: "WaxDeer",
  chains: [chainEndpoint],
  ui: webRenderer,
  walletPlugins: [
    new WalletPluginAnchor(),
    new WalletPluginCloudWallet(),
    new WalletPluginWombat(),
  ],
});

export function isSessionKitReady() {
  return sessionKit || false;
}

export const ChangeEndPoint = (endpoint: string) => async (dispatch: any) => {
  try {
    sessionKit.chains[0].url = endpoint;
    console.log("Endpoint changed successfully");
  } catch (e: any) {
    console.error("Failed to change endpoint:", e);
  }
};

const rpc = new JsonRpc(sessionKit.chains[0].url); // Default rpc instance for actions

// Utility function for table queries - using default rpc instance unless overridden
export const queryGameTable = async ({
  contract = GAME_CONTRACT,
  table,
  scope = GAME_CONTRACT,
  rpcOverride = rpc, // Default to the rpc instance defined above
  ...options
}: any) => {
  try {
    return await rpcOverride.get_table_rows({
      // Use rpcOverride (which defaults to 'rpc')
      json: true,
      code: contract,
      scope,
      table,
      show_payer: false,
      reverse: false,
      ...options,
    });
  } catch (e: any) {
    console.error("Table query error:", e);
    return { rows: [] };
  }
};

// Utility functions to handle loading and alerts
const withLoading =
  (action: any, loadingType = SHOW_LOADING) =>
  async (dispatch: any) => {
    dispatch({ type: loadingType });
    try {
      return await action(dispatch);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };

export const showAlert = (dispatch: any, type: string, msg: string) => {
  dispatch({ type: SHOW_ALERT, payload: { type, msg, show: true } });
};

// Action Functions - Using utilities and loadDataAction
export const AutoLogin = () =>
  withLoading(async (dispatch: any) => {
    try {
      const session: any = await sessionKit.restore();
      if (session) {
        await loadDataAction(
          dispatch,
          session.permissionLevel.actor,
          session,
          rpc
        );
      }
    } catch (e: any) {
      console.error("Auto login failed:", e);
    }
  });
// 1. First, fix the Reload function to make it properly dispatch and return a result
export const Reload = () => async (dispatch: any) => {
  try {
    dispatch({ type: SHOW_LOADING });
    const session: any = await sessionKit.restore();
    if (!session) {
      throw new Error("No active session found");
    }
    await loadDataAction(dispatch, session.permissionLevel.actor, session, rpc);
    return true;
  } catch (error) {
    console.error("Reload error:", error);
    dispatch({
      type: SHOW_ALERT,
      payload: { type: "error", msg: "Failed to reload data", show: true },
    });
    return false;
  } finally {
    dispatch({ type: HIDE_LOADING });
  }
};
export const WalletLogin = () =>
  withLoading(async (dispatch: any) => {
    try {
      const response = await sessionKit.login();
      const session: any = (await sessionKit.restore()) || response;
      if (!session) {
        return dispatch({ type: LOGIN_ERROR });
      }
      await loadDataAction(
        dispatch,
        session.permissionLevel.actor,
        session,
        rpc
      );
    } catch (e: any) {
      console.error("Wallet login error:", e);
    }
  });

export const BuyWorldAction = (world: number, callback: any) =>
  withLoading(async (dispatch: any) => {
    try {
      const session: any = await sessionKit.restore();

      const data = {
        account: GAME_CONTRACT,
        name: "buyworld",
        authorization: [session.permissionLevel],
        data: { world_id: world, user: session.actor?.toString() },
      };
      const result = await session.transact({ action: data });

      if (result) {
        dispatch({ type: BUY_WORLD, payload: { world } });
        showAlert(
          dispatch,
          "success",
          `Successfully purchased world-${world}.`
        );
        callback(true);
      } else {
        dispatch({
          type: EXPLORATION_ERROR,
          payload: { error: { message: "Buy World Error" } },
        });
        callback(false);
      }
    } catch (e: any) {
      console.error("Buy world error:", e);
      showAlert(dispatch, "error", e.toString());
      callback(false);
    }
  });

export const SetWorldAction = (world: number) => async (dispatch: any) => {
  dispatch({ type: WORLD_SELECT, payload: { world } });
};

// 3. Fix ClaimExploration to properly handle state updates and reloading
export const claimExploration =
  (exploreId: number) => async (dispatch: any) => {
    try {
      dispatch({ type: SHOW_LOADING });
      const session: any = await sessionKit.restore();
      if (!session) {
        throw new Error("No active session found");
      }

      const actions = [];
      actions.push({
        account: GAME_CONTRACT,
        name: "claimexplore",
        authorization: [session.permissionLevel],
        data: {
          explore_id: exploreId,
        },
      });

      const result = await session.transact({ actions });

      if (result) {
        dispatch({
          type: CLAIM_EXPLORATION_SUCCESS,
          payload: { exploreId },
        });
        dispatch({
          type: SHOW_ALERT,
          payload: {
            type: "success",
            msg: "Successfully claimed exploration rewards",
            show: true,
          },
        });

        // Explicitly await the reload
        await dispatch(Reload());
        return true;
      } else {
        throw new Error("Transaction failed");
      }
    } catch (e: any) {
      console.error("Exploration claim error:", e);
      dispatch({
        type: SHOW_ALERT,
        payload: { type: "error", msg: e.toString(), show: true },
      });
      return false;
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };

// 2. Fix the Exploration function to properly handle success/failure
export const Exploration =
  (world: number, asset_ids: Array<any>) => async (dispatch: any) => {
    try {
      dispatch({ type: SHOW_LOADING });
      const session: any = await sessionKit.restore();
      if (!session) {
        throw new Error("No active session found");
      }

      const actions = [];
      actions.push({
        account: GAME_CONTRACT,
        name: "sendexplore",
        authorization: [session.permissionLevel],
        data: { user: session.actor, asset_ids: asset_ids, world_id: world },
      });

      const result = await session.transact({ actions });

      if (result) {
        dispatch({
          type: EXPLORATION_SUCCESS,
          payload: { world, asset_ids },
        });
        dispatch({
          type: SHOW_ALERT,
          payload: {
            type: "success",
            msg: "Exploration started successfully",
            show: true,
          },
        });

        // Explicitly await the reload to ensure data is refreshed
        await dispatch(Reload());
        return true;
      } else {
        throw new Error("Transaction failed");
      }
    } catch (e: any) {
      console.error("Exploration error:", e);
      dispatch({
        type: EXPLORATION_ERROR,
        payload: { error: e.toString() },
      });
      dispatch({
        type: SHOW_ALERT,
        payload: { type: "error", msg: e.toString(), show: true },
      });
      return false;
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };

export const UnstakePool = (pool_id: number) =>
  withLoading(async (dispatch: any) => {
    try {
      const session: any = await sessionKit.restore();

      const data = {
        account: GAME_CONTRACT,
        name: "unstake",
        authorization: [session.permissionLevel],
        data: { stake_id: pool_id },
      };
      const result = await session.transact({ action: data });

      if (result) {
        showAlert(dispatch, "success", `Successfully unstaked `);
        return true;
      } else {
        dispatch({
          type: EXPLORATION_ERROR,
          payload: { error: { message: "Stake Pool Error" } },
        });
      }
    } catch (e: any) {
      console.error("Stake pool error:", e);
      showAlert(dispatch, "error", e.toString());
    }
  });

export const StakePool = (pool_id: number, amount: any) =>
  withLoading(async (dispatch: any) => {
    try {
      const session: any = await sessionKit.restore();
      const memo = `stake:${pool_id}`;
      const quantity = amount.toFixed(4) + " " + TOKEN_SYMBOL;

      const data = {
        account: GAME_CONTRACT,
        name: "addstake",
        authorization: [session.permissionLevel],
        data: { pool_id: pool_id, from: session.actor, quantity: quantity },
      };
      const result = await session.transact({ action: data });

      if (result) {
        dispatch({
          type: STAKE_SUCCESS,
          payload: { pool_id, amount: quantity },
        });
        showAlert(
          dispatch,
          "success",
          `Successfully staked ${amount} ${TOKEN_SYMBOL}`
        );
      } else {
        dispatch({
          type: EXPLORATION_ERROR,
          payload: { error: { message: "Stake Pool Error" } },
        });
      }
    } catch (e: any) {
      console.error("Stake pool error:", e);
      showAlert(dispatch, "error", e.toString());
    }
  });

export const ClaimStakePool = (stake_id: number) =>
  withLoading(async (dispatch: any) => {
    try {
      const session: any = await sessionKit.restore();

      const data = {
        account: GAME_CONTRACT,
        name: "claim",
        authorization: [session.permissionLevel],
        data: { user: session.actor, stake_id },
      };
      const result = await session.transact({ action: data });

      if (result) {
        dispatch({ type: CLAIM_STAKE, payload: { stake_id } });
        showAlert(dispatch, "success", "Successfully claimed reward");
      } else {
        showAlert(dispatch, "error", "Error while claiming reward");
      }
    } catch (e: any) {
      console.error("Claim stake pool error:", e);
      showAlert(dispatch, "error", e.toString());
    }
  });

// 5. Fix the ClaimTools function
export const ClaimTools = (ids: Array<any>) => async (dispatch: any) => {
  try {
    dispatch({ type: SHOW_LOADING });
    const session: any = await sessionKit.restore();
    if (!session) {
      throw new Error("No active session found");
    }

    const asset_ids = ids?.map((item: any) => item.asset_id) || [];

    const data = {
      account: GAME_CONTRACT,
      name: "claimtools",
      authorization: [session.permissionLevel],
      data: { user: session.actor, asset_ids: asset_ids },
    };

    let tokens = [] as any;
    for (const item of ids) {
      if (!item.tool_config) continue;
      var symbol = item.tool_config.reward_token?.split(" ")[1];
      var amount = parseFloat(item.tool_config.reward_token?.split(" ")[0]);
      if (tokens.find((token: any) => token.symbol === symbol)) {
        tokens.find((token: any) => token.symbol === symbol).amount += amount;
      } else {
        tokens.push({ symbol, amount });
      }
    }

    const result = await session.transact({ action: data });

    if (result) {
      dispatch({
        type: STAKE_TOOL_SUCCESS,
        payload: { ids },
      });

      const messages = [];
      for (const token of tokens) {
        messages.push(`${token.amount} ${token.symbol}`);
      }
      const rewardMessage = messages.length
        ? `You got ${messages.join(" and ")}`
        : "You got 0 rewards";

      dispatch({
        type: SHOW_ALERT,
        payload: { type: "success", msg: rewardMessage, show: true },
      });

      // Explicitly await the reload
      await dispatch(Reload());
      return true;
    } else {
      throw new Error("Transaction failed");
    }
  } catch (e: any) {
    console.error("Claim tools error:", e);
    dispatch({
      type: SHOW_ALERT,
      payload: { type: "error", msg: e.toString(), show: true },
    });
    return false;
  } finally {
    dispatch({ type: HIDE_LOADING });
  }
};

// 4. Fix the StakeTools function
export const StakeTools =
  (ids: Array<any>, reward_amount: number, type: string) =>
  async (dispatch: any) => {
    try {
      dispatch({ type: SHOW_LOADING });
      const session: any = await sessionKit.restore();
      if (!session) {
        throw new Error("No active session found");
      }

      const data = {
        account: GAME_CONTRACT,
        name: "staketool",
        authorization: [session.permissionLevel],
        data: { user: session.actor, asset_ids: ids, type: 1 },
      };

      const result = await session.transact({ action: data });

      if (result) {
        // Explicitly await the reload
        await dispatch(Reload());
        return true;
      } else {
        throw new Error("Transaction failed");
      }
    } catch (e: any) {
      console.error("Stake tools error:", e);
      dispatch({
        type: SHOW_ALERT,
        payload: { type: "error", msg: e.toString(), show: true },
      });
      // Still reload to make sure the UI is in sync with the current state
      await dispatch(Reload());
      return false;
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };

export const DepositTokenAction = (depositAmount: any, depositSymbol: any) =>
  withLoading(async (dispatch: any) => {
    try {
      const session: any = await sessionKit.restore();
      const memo = "deposit";
      const contract =
        depositSymbol === "ANTLERS" ? TOKEN_CONTRACT : FEED_TOKEN_CONTRACT;
      const quantity = Number(depositAmount).toFixed(4) + " " + depositSymbol;

      const data = {
        account: contract,
        name: "transfer",
        authorization: [session.permissionLevel],
        data: { from: session.actor, to: GAME_CONTRACT, quantity, memo },
      };
      const result = await session.transact({ action: data });

      if (result) {
        showAlert(
          dispatch,
          "success",
          `Successfully deposited ${depositAmount} ${depositSymbol}.`
        );
        await Reload();
      } else {
        showAlert(dispatch, "error", "Deposit Error");
      }
    } catch (e: any) {
      console.error("Deposit token error:", e);
      showAlert(dispatch, "error", e.toString());
    }
  });

export const WithdrawTokenAction = (depositSymbol: any, withdrawAmount: any) =>
  withLoading(async (dispatch: any) => {
    try {
      const session: any = await sessionKit.restore();

      const quantity = Number(withdrawAmount).toFixed(4) + " " + depositSymbol;

      const data = {
        account: GAME_CONTRACT,
        name: "withdrawtkn",
        authorization: [session.permissionLevel],
        data: { user: session.actor, quantity },
      };
      const result = await session.transact({ action: data });

      if (result) {
        showAlert(
          dispatch,
          "success",
          `Successfully withdrew ${withdrawAmount} ${depositSymbol}.`
        );
        await Reload();
      } else {
        showAlert(dispatch, "error", "Withdraw Error");
      }
    } catch (e: any) {
      console.error("Withdraw token error:", e);
      showAlert(dispatch, "error", e.toString());
    }
  });
export const BuyGameTokenAction = (amount: any, receive_amount: any) =>
  withLoading(async (dispatch: any) => {
    try {
      const session: any = await sessionKit.restore();
      const memo = "buy:gold";
      const quantity = Number(amount).toFixed(8) + " " + SYSTEM_TOKEN_SYMBOL;

      const data = {
        account: SYSTEM_TOKEN_CONTRACT,
        name: "transfer",
        authorization: [session.permissionLevel],
        data: { from: session.actor, to: GAME_CONTRACT, quantity, memo },
      };
      const result = await session.transact({ action: data });

      if (result) {
        dispatch({ type: BUY_GOLD, payload: { amount, receive_amount } });
        showAlert(
          dispatch,
          "success",
          `Successfully bought ${receive_amount} ${GOLD_TOKEN_SYMBOL}.`
        );
        await Reload();
      } else {
        showAlert(dispatch, "error", "Buy Gold Error");
      }
    } catch (e: any) {
      console.error("Buy game token error:", e);
      showAlert(dispatch, "error", e.toString());
    }
  });

export const DeerStake = (asset_ids: any) =>
  withLoading(async (dispatch: any) => {
    try {
      const session: any = await sessionKit.restore();

      const data = {
        account: GAME_CONTRACT,
        name: "stakedeer",
        authorization: [session.permissionLevel],
        data: {
          user: session.actor,
          asset_ids: asset_ids,
        },
      };
      const result = await session.transact({ action: data });

      if (result) {
        showAlert(
          dispatch,
          "success",
          `Successfully staked ${asset_ids.length} assets`
        );
        dispatch({
          type: ASSET_STAKE_SUCCESS,
          payload: { asset_ids },
        });
        await Reload();
      } else {
        showAlert(dispatch, "error", "Stake Error");
      }
    } catch (e: any) {
      console.error("Stake error:", e);
      showAlert(dispatch, "error", e.toString());
    }
  });

export const DeerUpgrade = (
  asset_id: any,
  current_level: any,
  targetLevel: number
) =>
  withLoading(async (dispatch: any) => {
    try {
      const session: any = await sessionKit.restore();
      const memo = `upgrade:${asset_id}`;
      const quantity =
        ((current_level * current_level) / 2).toFixed(4) +
        " " +
        FEED_TOKEN_SYMBOL;

      const data = {
        account: GAME_CONTRACT,
        name: "upgradedeer",
        authorization: [session.permissionLevel],
        data: {
          user: session.actor,
          asset_ids: [asset_id],
          targetLevel: targetLevel,
        },
      };
      const result = await session.transact({ action: data });

      if (result) {
        dispatch({
          type: LEVELUP_SUCCESS,
          payload: { asset_id, current_level },
        });
        showAlert(
          dispatch,
          "success",
          `Successfully leveled up asset ${asset_id}`
        );
      } else {
        showAlert(dispatch, "error", "Level up Error");
      }
    } catch (e: any) {
      console.error("Level upgrade error:", e);
      showAlert(dispatch, "error", e.toString());
    }
  });

export const LevelUpgrade = (
  asset_id: any,
  current_level: any,
  targetLevel: number
) =>
  withLoading(async (dispatch: any) => {
    try {
      const session: any = await sessionKit.restore();
      const memo = `upgrade:${asset_id}`;
      const quantity =
        ((current_level * current_level) / 2).toFixed(4) +
        " " +
        FEED_TOKEN_SYMBOL;

      const data = {
        account: FEED_TOKEN_CONTRACT,
        name: "transfer",
        authorization: [session.permissionLevel],
        data: { from: session.actor, to: GAME_CONTRACT, quantity, memo },
      };
      const result = await session.transact({ action: data });

      if (result) {
        dispatch({
          type: LEVELUP_SUCCESS,
          payload: { asset_id, current_level },
        });
        showAlert(
          dispatch,
          "success",
          `Successfully leveled up asset ${asset_id}`
        );
      } else {
        showAlert(dispatch, "error", "Level up Error");
      }
    } catch (e: any) {
      console.error("Level upgrade error:", e);
      showAlert(dispatch, "error", e.toString());
    }
  });

export const LevelupBoost = (asset_id: any, boostAmount: number) =>
  withLoading(async (dispatch: any) => {
    try {
      const session: any = await sessionKit.restore();

      const data = {
        account: GAME_CONTRACT,
        name: "boostlvlup",
        authorization: [session.permissionLevel],
        data: { user: session.actor, asset_id, amount: boostAmount },
      };
      const result = await session.transact({ action: data });

      if (result) {
        dispatch({
          type: LEVELUP_BOOST_SUCCESS,
          payload: { asset_id, boostAmount },
        });
        showAlert(
          dispatch,
          "success",
          `Successfully boosted asset ${asset_id}`
        );
      } else {
        showAlert(dispatch, "error", "Boost Error");
      }
    } catch (e: any) {
      console.error("Level up boost error:", e);
      showAlert(dispatch, "error", e.toString());
    }
  });

export const EnterTournament =
  (asset_ids: Array<any>, id: any) => async (dispatch: any) => {
    try {
      const session: any = await sessionKit.restore();

      const actions = [
        {
          account: TOKEN_CONTRACT,
          name: "transfer",
          authorization: [session.permissionLevel],
          data: {
            from: session.actor,
            to: GAME_CONTRACT,
            quantity: asset_ids.length.toFixed(4) + " " + TOKEN_SYMBOL,
            memo: "tournament:" + id,
          },
        },
        {
          account: GAME_CONTRACT,
          name: "entertour",
          authorization: [session.permissionLevel],
          data: {
            user: session.actor,
            asset_ids: asset_ids,
            tournament_id: id,
            memo: "enter tournament",
          },
        },
      ];

      const args = { actions };
      const result = await session.transact(args);

      if (result) {
        dispatch({ type: ENTER_TOURNAMENT_SUCCESS, payload: { id } });
        showAlert(dispatch, "success", "Successfully entered in Tournament");
      }
    } catch (e: any) {
      console.error("Enter tournament error:", e);
      showAlert(dispatch, "error", e.toString());
    }
  };

export const GetTournamentList = () => async (dispatch: any) => {
  try {
    const result = await queryGameTable({ table: "tournaments", limit: 10 }); // Using default rpc
    const tournaments = result.rows;
    dispatch({ type: GET_TOURNAMENT_LIST, payload: { tournaments } });
  } catch (e: any) {
    console.error("Get tournament list error:", e);
    showAlert(dispatch, "error", e.toString());
  }
};

export const GetConfigs = () => async (dispatch: any) => {
  try {
    const result = await queryGameTable({ table: "rewardinfos", limit: 1000 }); // Using default rpc
    const data = result.rows;
    dispatch({ type: GET_CONFIGS, payload: { data } });
  } catch (e: any) {
    console.error("Get configs error:", e);
    showAlert(dispatch, "error", e.toString());
  }
};

export const GetLeaderBoard = () => async (dispatch: any) => {
  try {
    const session: any = await sessionKit.restore();

    const [leaderboardResult, myScoreResult] = await Promise.all([
      queryGameTable({ table: "leaderboard", limit: 1000 }), // Using default rpc
      queryGameTable({
        table: "leaderboard",
        limit: 1,
        lower_bound: session.actor?.toString(),
        upper_bound: session.actor?.toString(),
      }), // Using default rpc
    ]);

    // Transform response if needed to match the contract structure
    var leaderboard = leaderboardResult.rows.map((row: any) => ({
      username: row.username,
      deer_used: row.deer_used,
      explore_count: row.explore_count,
      reward: row.reward,
    }));

    //filter out username = f4wqy.wam
    leaderboard = leaderboard.filter(
      (item: any) => item.username !== "f4wqy.wam"
    );

    const myScore =
      myScoreResult.rows.length > 0
        ? {
            username: myScoreResult.rows[0].username,
            deer_used: myScoreResult.rows[0].deer_used,
            explore_count: myScoreResult.rows[0].explore_count,
            reward: myScoreResult.rows[0].reward,
          }
        : null;

    dispatch({ type: GET_LEADERBOARD, payload: { leaderboard, myScore } });
  } catch (e: any) {
    console.error("Get leaderboard error:", e);
    showAlert(dispatch, "error", e.toString());
  }
};

export const ShowAlert =
  (type: string, msg: string, show: boolean) => async (dispatch: any) => {
    dispatch({ type: SHOW_ALERT, payload: { type, msg, show } });
  };

export const WalletLogout = () => async (dispatch: any) => {
  dispatch({
    type: LOGIN_SUCCESS,
    payload: {
      session: null,
      balance: 0,
      assets: [],
      world_cost: [],
      open_worlds: [1],
      reward_info: [],
      tool_configs: [],
      deer_configs: [],
      staking_pool: [],
      pool_info: [],
    },
  });
};

export const LoginRequest = (id: number) => ({
  type: LOGIN_REQUEST,
  payload: {},
});

export const showLoading = (isShow: boolean) => async (dispatch: any) => {
  dispatch({ type: isShow ? SHOW_LOADING : HIDE_LOADING, payload: {} });
};

// Export utility functions if you need them in loader.ts or other parts of your app
export { withLoading };

export const ClaimExploreAction = (exploreId: number) =>
  withLoading(async (dispatch: any) => {
    try {
      const session: any = await sessionKit.restore();

      const data = {
        account: GAME_CONTRACT,
        name: "claimexplore",
        authorization: [session.permissionLevel],
        data: { explore_id: exploreId },
      };

      const result = await session.transact({ action: data });

      if (result) {
        dispatch({ type: CLAIM_EXPLORATION_SUCCESS, payload: { exploreId } });
        showAlert(
          dispatch,
          "success",
          "Successfully claimed exploration rewards"
        );
        await Reload();
        return true;
      } else {
        showAlert(dispatch, "error", "Failed to claim exploration");
        return false;
      }
    } catch (e: any) {
      console.error("Claim exploration error:", e);
      showAlert(dispatch, "error", e.toString());
      return false;
    }
  });
