import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Modal,
  CircularProgress,
  Slider,
  Stack,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store";
import { GetTimeString } from "../../utiles";
import { LevelupBoost, DeerUpgrade } from "../../store/user/actions";

const URI_PREFIX = "https://ipfs.io/ipfs/";

interface UpgradeModalProps {
  open: boolean;
  onClose: () => void;
  nft: any; // NFT data to display in the modal
}

// Custom hook for countdown timer
const useCountdown = (initialTime: number) => {
  const [secondsRemaining, setSecondsRemaining] = useState(initialTime);
  const [isRunning, setIsRunning] = useState(false);

  const start = useCallback(
    (startTime?: number) => {
      setSecondsRemaining(startTime !== undefined ? startTime : initialTime);
      setIsRunning(true);
    },
    [initialTime]
  );

  const stop = useCallback(() => {
    setIsRunning(false);
  }, []);

  useEffect(() => {
    if (!isRunning) return;

    const intervalId = setInterval(() => {
      setSecondsRemaining((prevSeconds) => {
        if (prevSeconds <= 1) {
          clearInterval(intervalId);
          setIsRunning(false);
          return 0;
        }
        return prevSeconds - 1;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [isRunning]);

  return {
    secondsRemaining,
    startCountdown: start,
    stopCountdown: stop,
    isRunning: isRunning,
  };
};

const UpgradeModal: React.FC<UpgradeModalProps> = ({ open, onClose, nft }) => {
  const dispatch = useDispatch<any>();
  const userModule = useSelector((state: AppState) => state.userModule);
  const loginInfo = userModule.loginInfo;
  const [loadingUpgrade, setLoadingUpgrade] = useState(false);
  const [loadingBoost, setLoadingBoost] = useState(false);
  const [targetLevel, setTargetLevel] = useState(0);

  // Reset state and directly use nft prop throughout instead of keeping a copy
  // This ensures we always display the currently selected NFT

  const {
    secondsRemaining: uTime,
    startCountdown: startTimer,
    stopCountdown: stopTimer,
  } = useCountdown(0);

  // Reset and initialize state when modal opens or NFT changes
  useEffect(() => {
    // Reset timer when modal closes
    if (!open) {
      stopTimer();
      return;
    }

    // Return early if no NFT data is available
    if (!nft) return;

    // Set target level to next level
    setTargetLevel(nft.level + 1);

    // Check upgrade status and start timer if needed
    const currentUnixTime = Math.floor(Date.now() / 1000);
    const delayTime = nft.upgrade_end_at - currentUnixTime;

    if (delayTime > 0) {
      startTimer(delayTime);
    } else {
      stopTimer();
    }
  }, [nft, open, startTimer, stopTimer]);

  // CALCULATE UPGRADE COST
  const calculateUpgradeCost = useCallback(() => {
    if (!nft) return { antlers: 0, gold: 0, delay: 0 };

    let totalAntlers = 0;
    let totalGold = 0;
    let totalDelay = 0;

    const deer_config = nft.deer_config || nft.tool_config;
    if (!deer_config) return { antlers: 0, gold: 0, delay: 0 };

    const level_costa = deer_config.level_costa;
    const level_costb = deer_config.level_costb;
    const level_delay = deer_config.level_delay;
    const levelc = parseInt(nft.level);

    for (let level = levelc; level < targetLevel; level++) {
      const newLevel = level + 1;
      const foundCosta = level_costa?.find((a: any) => a.key == newLevel);
      const foundCostb = level_costb?.find((a: any) => a.key == newLevel);
      const foundDelay = level_delay?.find((a: any) => a.key == newLevel);

      if (!foundCosta || !foundCostb || !foundDelay)
        return { antlers: 0, gold: 0, delay: 0 };

      totalGold += parseFloat(foundCosta.value);
      totalAntlers += parseFloat(foundCostb.value);
      totalDelay += parseFloat(foundDelay.value);
    }

    return { antlers: totalAntlers, gold: totalGold, delay: totalDelay };
  }, [nft, targetLevel]);

  const upgradeCost = React.useMemo(
    () => calculateUpgradeCost(),
    [calculateUpgradeCost]
  );

  // CALCULATE BOOST COST
  const calculateBoostCost = useCallback(() => {
    if (!nft) return 0;
    const boostCost = 20;
    return boostCost;
  }, [nft]);

  const boostCost = React.useMemo(
    () => calculateBoostCost(),
    [calculateBoostCost]
  );

  const levelup = useCallback(async () => {
    if (!nft) return;
    setLoadingUpgrade(true);
    try {
      await dispatch(DeerUpgrade(nft.asset_id, nft.level, targetLevel));
      onClose();
    } catch (error) {
      console.error("Upgrade failed:", error);
    } finally {
      setLoadingUpgrade(false);
    }
  }, [dispatch, nft, targetLevel, onClose]);

  const levelupboost = useCallback(async () => {
    if (!nft) return;
    setLoadingBoost(true);
    try {
      await dispatch(LevelupBoost(nft.asset_id, 1));
      onClose();
    } catch (error) {
      console.error("Boost failed:", error);
    } finally {
      setLoadingBoost(false);
    }
  }, [dispatch, nft, onClose]);

  if (!nft) return null;

  // Get max level from config with fallback
  const maxLevel = nft.deer_config?.level_costa?.length
    ? nft.deer_config.level_costa.length + 1
    : nft.level + 5;

  const modalStyle = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "90%", sm: 400 },
    bgcolor: "#1a203c",
    color: "white",
    border: "4px solid #ea923e",
    boxShadow: 24,
    textAlign: "center",
    borderRadius: "16px",
    p: 4,
    maxHeight: "90vh",
    overflow: "auto",
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={modalStyle}>
        <Typography
          id="upgrade-modal-title"
          variant="h4"
          component="h2"
          sx={{ mb: 2, color: "#42fdff" }}
        >
          NFT Detail
        </Typography>

        <Box sx={{ width: "100%", maxWidth: "300px", margin: "0 auto", mb: 2 }}>
          <img
            src={URI_PREFIX + nft.data?.img}
            style={{ width: "100%", borderRadius: "8px" }}
            alt={nft.data?.name || "NFT Image"}
          />
        </Box>

        <Grid container spacing={2} sx={{ mb: 3 }}>
          <Grid item xs={6} sx={{ textAlign: "right" }}>
            <Typography color="#939393"> Status: </Typography>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: "left" }}>
            <Typography>
              {uTime === 0
                ? "Available"
                : `Upgrading (${GetTimeString(uTime)})`}
            </Typography>
          </Grid>

          <Grid item xs={6} sx={{ textAlign: "right" }}>
            <Typography color="#939393"> Level: </Typography>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: "left" }}>
            <Typography>{nft.level} </Typography>
          </Grid>

          <Grid item xs={6} sx={{ textAlign: "right" }}>
            <Typography color="#939393"> Type: </Typography>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: "left" }}>
            <Typography>{nft.schema?.schema_name} </Typography>
          </Grid>
        </Grid>

        {nft.deer_config && (
          <Box
            sx={{
              backgroundColor: "#1c1d3e",
              borderRadius: "12px",
              padding: "20px",
              mb: 3,
            }}
          >
            {uTime === 0 ? (
              <>
                <Typography variant="subtitle1" mb={2}>
                  Select Target Level:
                </Typography>
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  sx={{ mb: 3 }}
                >
                  <Typography>{parseInt(nft.level) + 1}</Typography>
                  <Slider
                    value={targetLevel}
                    onChange={(_, newValue) =>
                      setTargetLevel(newValue as number)
                    }
                    min={parseInt(nft.level) + 1}
                    max={maxLevel}
                    step={1}
                    marks
                    valueLabelDisplay="auto"
                    sx={{ color: "#42fdff" }}
                  />
                  <Typography> {maxLevel} </Typography>
                </Stack>

                <Typography variant="subtitle1" mb={2}>
                  Upgrade Cost({nft.level} → {targetLevel}):
                </Typography>

                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="center"
                  mb={3}
                >
                  <Box>
                    <Typography color="#8aff8a">
                      {upgradeCost.antlers.toFixed(4)} ANTLERS
                    </Typography>
                  </Box>
                  <Box>
                    <Typography color="#ffd700">
                      {upgradeCost.gold.toFixed(1)} GOLD
                    </Typography>
                  </Box>
                  <Box>
                    <Typography color="#ffd700">
                      {(upgradeCost.delay / 86400).toFixed(1)} DAYS
                    </Typography>
                  </Box>
                </Stack>

                <Button
                  variant="contained"
                  color="success"
                  sx={{
                    borderRadius: "50px",
                    padding: "10px 25px",
                    fontSize: { xs: "14px", sm: "16px" },
                  }}
                  onClick={levelup}
                  disabled={loadingUpgrade}
                >
                  {loadingUpgrade ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    `Upgrade to Level ${targetLevel}`
                  )}
                </Button>
              </>
            ) : (
              <>
                <Typography variant="subtitle1" mb={2}>
                  Boost Cost: {boostCost} EXP
                </Typography>

                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    borderRadius: "50px",
                    padding: "10px 25px",
                    fontSize: { xs: "14px", sm: "16px" },
                  }}
                  onClick={levelupboost}
                  disabled={loadingBoost}
                >
                  {loadingBoost ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Boost Level Up"
                  )}
                </Button>
              </>
            )}
          </Box>
        )}

        <Button
          onClick={onClose}
          variant="outlined"
          color="inherit"
          sx={{ borderRadius: "50px" }}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export default UpgradeModal;
