import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../store";
import { StakeTools, ClaimTools, Reload } from "../store/user/actions";
import NFTCard from "../components/NFTCard";
import UpgradeModal from "./Inventory/UpgradeModal";
import "./Inventory/Inventory.css";
import { toast } from "react-toastify";

const uri_prefix = "https://ipfs.io/ipfs/";

export const Tools = () => {
  const userModule = useSelector((state: AppState) => state.userModule);
  const loginInfo = userModule.loginInfo;
  const dispatch = useDispatch<any>();

  const [toolNfts, setToolNfts] = useState([]);
  const [filteredNfts, setFilteredNfts] = useState([]);

  const filters = ["All", "Shovel", "Pickaxe"];
  const [filterType, setFilterType] = useState("All");
  const [sortBy, setSortBy] = useState("rarity");

  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);
  const [selectedNftForUpgrade, setSelectedNftForUpgrade] = useState<any>(null);
  const [selectedNFTForModal, setSelectedNFTForModal] = useState<any | null>(
    null
  ); // State for selected NFT
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility

  const openUpgradeModal = (nft: any) => {
    setSelectedNFTForModal(nft);
    setIsModalOpen(true);
  };

  const closeUpgradeModal = () => {
    setIsModalOpen(false);
    setSelectedNFTForModal(null); // Clear selected NFT when modal closes
  };

  const handleFilterChange = (e: any) => {
    setFilterType(e.target.value);
  };

  const handleSortByChange = (e: any) => {
    setSortBy(e.target.value);
  };

  useEffect(() => {
    const processTools = async () => {
      let tools = loginInfo.assets?.filter((item: any) => item?.tool_config);

      tools?.forEach((item: any) => {
        // Map exploration_time to last_claimed from blockchain
        item.last_claimed = item.exploration_time || 0;
      });
      console.log("Tools", tools);
      setToolNfts(tools || []);
    };

    processTools();
  }, [loginInfo.assets]);

  useEffect(() => {
    const applyFiltersAndSort = async () => {
      let _filteredNfts = [...toolNfts];

      if (filterType !== "All") {
        _filteredNfts = _filteredNfts.filter((item: any) =>
          item.data.name.includes(filterType)
        );
      }

      if (sortBy === "rarity") {
        _filteredNfts.sort((a: any, b: any) => {
          const rewardA = parseFloat(
            b.tool_config.reward_token.split(" ")?.[0] || "0"
          );
          const rewardB = parseFloat(
            a.tool_config.reward_token.split(" ")?.[0] || "0"
          );
          return rewardA - rewardB;
        });
      } else if (sortBy === "time") {
        _filteredNfts.sort(
          (a: any, b: any) => b.exploration_time - a.exploration_time
        );
      }
      console.log("filteredNfts", _filteredNfts);
      setFilteredNfts(_filteredNfts);
    };

    applyFiltersAndSort();
  }, [toolNfts, filterType, sortBy]);

  const handleStake = async (assetId: string) => {
    const selectedTools = toolNfts.filter(
      (item: any) => assetId === item.asset_id
    );
    let rewardAmount = 0;
    selectedTools.forEach((item: any) => {
      const rewardToken = item.tool_config.reward_token;
      const idx = parseFloat(rewardToken.split(" ")?.[0] || "-1");
      if (idx !== -1) {
        rewardAmount += idx;
      }
    }); // Dispatch and await the result
    const success = await dispatch(
      StakeTools([assetId], rewardAmount, filterType)
    );

    if (success) {
      toast.success("Tools staked successfully.");
    }
  };

  const handleClaim = async (assetId: any) => {
    const success = await dispatch(ClaimTools([assetId]));
    if (success) {
      toast.success("Tools claimed successfully.");
    }
  };

  // 7. Fix in Tools.tsx - Update the handleStakeAll function
  const handleStakeAll = async () => {
    const stakeableNfts = filteredNfts.filter(
      (item: any) => !item.exploration_time
    );

    if (stakeableNfts.length === 0) {
      toast.error("No stakeable NFTs available.");
      return;
    }

    const assetIds = stakeableNfts.map((item: any) => item.asset_id);

    let totalRewardAmount = 0;
    stakeableNfts.forEach((item: any) => {
      const rewardToken = item.tool_config.reward_token;
      const idx = parseFloat(rewardToken.split(" ")?.[0] || "-1");
      if (idx !== -1) {
        totalRewardAmount += idx;
      }
    });

    // Dispatch and await the result
    const success = await dispatch(
      StakeTools(assetIds, totalRewardAmount, filterType)
    );

    if (success) {
      toast.success("Tools staked successfully.");
    }
  };

  const handleClaimAll = () => {
    const currentUnixTime = Math.floor(Date.now() / 1000);
    const claimableNfts = filteredNfts.filter(
      (item: any) => item.last_claimed <= currentUnixTime
    );
    if (claimableNfts.length === 0) {
      alert("No claimable NFTs available.");
      return;
    }
    dispatch(ClaimTools(claimableNfts));
    toast.success("Tools claimed successfully");
    dispatch(Reload());
  };

  // Upgrade Modal Handlers
  const handleOpenUpgradeModal = (nft: any) => {
    setSelectedNftForUpgrade(nft);
    setIsUpgradeModalOpen(true);
  };

  const handleCloseUpgradeModal = () => {
    setIsUpgradeModalOpen(false);
    setSelectedNftForUpgrade(null);
  };

  const handleUpgrade = () => {
    if (selectedNftForUpgrade) {
      console.log(
        "Upgrade action triggered for:",
        selectedNftForUpgrade.asset_id
      );
      // dispatch(UpgradeTool(selectedNftForUpgrade.asset_id)); // Replace with your actual dispatch
      handleCloseUpgradeModal();
    }
  };

  return (
    <Box className="inventory-container" sx={{ p: { xs: 2, md: 3 } }}>
      {/* Title with background image */}
      <Box
        sx={{
          position: "relative",
          width: "100%",
          textAlign: "center",
          mb: 4,
        }}
      >
        <Typography
          variant="h4"
          sx={{
            position: "relative",
            zIndex: 2,
            fontWeight: "bold",
            color: "white",
            textShadow: "2px 2px 3px rgba(0,0,0,0.5)",
          }}
        >
          TOOLS
        </Typography>
        <Box
          component="img"
          src="/images/title_bg.png"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "100%", sm: "300px" },
            zIndex: 1,
          }}
        />
      </Box>

      {toolNfts && toolNfts.length > 0 ? (
        <>
          {/* Filter and Sort Section */}
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <select
                className="rpc-endpoints"
                onChange={handleFilterChange}
                style={{
                  backgroundColor: "#333",
                  color: "white",
                  border: "1px solid #666",
                  borderRadius: "8px",
                  padding: "10px",
                  fontSize: "16px",
                  cursor: "pointer",
                }}
              >
                {filters.map((item) => (
                  <option
                    key={item}
                    value={item}
                    style={{ backgroundColor: "#333", color: "white" }}
                  >
                    {item.toUpperCase()}
                  </option>
                ))}
              </select>
              <select
                className="rpc-endpoints"
                onChange={handleSortByChange}
                style={{
                  backgroundColor: "#333",
                  color: "white",
                  border: "1px solid #666",
                  borderRadius: "8px",
                  padding: "10px",
                  fontSize: "16px",
                  cursor: "pointer",
                }}
              >
                <option
                  value="rarity"
                  style={{ backgroundColor: "#333", color: "white" }}
                >
                  Rarity
                </option>
                <option
                  value="time"
                  style={{ backgroundColor: "#333", color: "white" }}
                >
                  Time
                </option>
              </select>
            </Box>
            <Box sx={{ display: "flex", gap: 1 }}>
              <Button
                variant="contained"
                sx={{
                  bgcolor: "#5cb85c",
                  color: "white",
                  "&:hover": { bgcolor: "#449d44" },
                  fontWeight: "bold",
                  borderRadius: "8px",
                }}
                onClick={handleStakeAll}
                disabled={
                  filteredNfts.filter((item: any) => !item.exploration_time)
                    .length === 0
                }
              >
                Stake All
              </Button>
              <Button
                variant="contained"
                sx={{
                  bgcolor: "#d9534f",
                  color: "white",
                  "&:hover": { bgcolor: "#c9302c" },
                  fontWeight: "bold",
                  borderRadius: "8px",
                }}
                onClick={handleClaimAll}
                disabled={
                  filteredNfts.filter(
                    (item: any) => item.exploration_time < Date.now() / 1000
                  ).length === 0
                }
              >
                Claim All(
                {filteredNfts
                  .filter(
                    (item: any) => item.exploration_time < Date.now() / 1000
                  )
                  ?.reduce(
                    (acc: number, item: any) =>
                      acc +
                      parseFloat(item.tool_config.reward_token.split(" ")[0]) *
                        (item.exploration_time < Date.now() / 1000
                          ? 0
                          : (item.exploration_time - Date.now() / 1000) / 3600),
                    0
                  )}
                )
              </Button>
            </Box>
          </Box>

          {/* NFT Grid Section */}
          <Box
            className="inventory-grid-container"
            sx={{ overflowY: "auto", maxHeight: "75vh" }}
          >
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              {filteredNfts?.map((item: any, index: number) => (
                <NFTCard
                  key={item.asset_id}
                  uri={uri_prefix + item.data.img}
                  name={item.data.name}
                  assetID={item.asset_id}
                  stakedTime={item.exploration_time}
                  onClaim={() => handleClaim(item)}
                  onStake={() => handleStake(item.asset_id)}
                  nftData={item}
                  onUpgrade={() => openUpgradeModal(item)}
                />
              ))}
            </Grid>
          </Box>
        </>
      ) : (
        <Box
          sx={{
            bgcolor: "rgba(0, 0, 0, 0.5)",
            borderRadius: "16px",
            color: "white",
            p: 4,
            textAlign: "center",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
          }}
        >
          <Typography variant="h6" gutterBottom>
            There are no tool NFTs.
          </Typography>
          <Typography variant="body1">
            Buy tool NFT{" "}
            <a
              href="https://wax.atomichub.io/market?blockchain=wax-mainnet&collection_name=waxdeerjc&order=asc&primary_chain=wax-mainnet&schema_name=tools&sort=price&symbol=WAX#sales"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#d9534f" }}
            >
              Here
            </a>
            .
          </Typography>
        </Box>
      )}

      <UpgradeModal
        open={isModalOpen}
        onClose={closeUpgradeModal}
        nft={selectedNFTForModal}
      />
    </Box>
  );
};
