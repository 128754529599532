import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Button,
  Stack,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store";
import DeerCard from "../../components/DeerCard";
import "./Inventory.css";
import UpgradeModal from "./UpgradeModal"; // Import UpgradeModal
import { DeerStake, Reload } from "../../store/user/actions";
import { toast } from "react-toastify";

const uri_prefix = "https://ipfs.io/ipfs/";

const filters = ["fawn", "og.deer", "abyssal", "promo"];

const filterOptions = [
  { value: "all", label: "All Types" },
  { value: "og", label: "OG Deers" },
  { value: "fawn", label: "Fawn Deers" },
  { value: "abyssal", label: "Abyssal Deers" },
  { value: "promo", label: "Promo Deers" },
];

const sortOptions = [
  { value: "level_desc", label: "Level (Highest First)" },
  { value: "level_asc", label: "Level (Lowest First)" },
  { value: "name_asc", label: "Name (A-Z)" },
  { value: "name_desc", label: "Name (Z-A)" },
  { value: "status", label: "Status (Available First)" },
];

export const Inventory = () => {
  const userModule = useSelector((state: AppState) => state.userModule);
  const loginInfo = userModule.loginInfo;
  const [nfts, setNFTs] = useState<any[]>([]);
  const [filteredNfts, setFilteredNfts] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [filterType, setFilterType] = useState("all");
  const [sortBy, setSortBy] = useState("status");
  const [selectedNFTForModal, setSelectedNFTForModal] = useState<any | null>(
    null
  ); // State for selected NFT
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility
  const dispatch = useDispatch<any>();

  useEffect(() => {
    if (loginInfo.assets && loginInfo.assets.length > 0) {
      setLoading(true);
      setNFTs(loginInfo.assets);
      applyFiltersAndSort(loginInfo.assets, filterType, sortBy);
      setLoading(false);
    } else {
      setNFTs([]);
      setFilteredNfts([]);
    }
  }, [loginInfo.assets, filterType, sortBy]);

  const handleStakeAll = () => {
    const stakeableNfts = filteredNfts.filter(
      (item: any) => !item.stakedConfig
    );
    var assetIds = stakeableNfts.map((item: any) => item.asset_id);
    const maxCount = 100;
    assetIds.splice(maxCount);

    dispatch(DeerStake(assetIds));
    toast.success(`Staked ${assetIds.length} Deers..`);
    dispatch(Reload());
  };

  const handleStake = (nft: any) => {
    const assetIds = [nft.asset_id];

    dispatch(DeerStake(assetIds));
  };

  const applyFiltersAndSort = (
    currentNfts: any[],
    filterValue: string,
    sortValue: string
  ) => {
    let _filteredNfts = [
      ...currentNfts?.filter((item: any) => item.deer_config),
    ];

    if (filterValue !== "all") {
      _filteredNfts = _filteredNfts.filter(
        (item: any) => item.schema.schema_name === filterValue
      );
    }

    _filteredNfts.sort((a: any, b: any) => {
      switch (sortValue) {
        case "level_desc":
          return b.level - a.level;
        case "level_asc":
          return a.level - b.level;
        case "name_asc":
          return a.data.name.localeCompare(b.data.name);
        case "name_desc":
          return b.data.name.localeCompare(a.data.name);
        case "status":
          const timeA = a.exploration_time || Infinity;
          const timeB = b.exploration_time || Infinity;
          return timeA - timeB;
        default:
          return 0;
      }
    });
    setFilteredNfts(_filteredNfts);
  };

  const handleFilterChange = (event: any) => {
    const filterValue = event.target.value as string;
    setFilterType(filterValue);
    applyFiltersAndSort(nfts, filterValue, sortBy);
  };

  const handleSortChange = (event: any) => {
    const sortValue = event.target.value as string;
    setSortBy(sortValue);
    applyFiltersAndSort(nfts, filterType, sortValue);
  };

  const openUpgradeModal = (nft: any) => {
    setSelectedNFTForModal(nft);
    setIsModalOpen(true);
  };

  const closeUpgradeModal = () => {
    setIsModalOpen(false);
    setSelectedNFTForModal(null); // Clear selected NFT when modal closes
  };

  return (
    <Box className="inventory-container" sx={{ p: { xs: 2, md: 3 } }}>
      <Typography
        variant="h4"
        component="h1"
        gutterBottom
        className="inventory-title"
      >
        Your Deer Inventory
      </Typography>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 2, sm: 3 }}
        className="inventory-filter-bar"
        sx={{ mb: 2, justifyContent: "space-between", alignItems: "center" }}
      >
        <FormControl variant="outlined" className="inventory-filter-form">
          <InputLabel id="nft-filter-label" className="inventory-filter-label">
            Filter by Type
          </InputLabel>
          <Select
            labelId="nft-filter-label"
            id="nft-type-filter"
            value={filterType}
            onChange={handleFilterChange}
            label="Filter by Type"
            className="inventory-select"
          >
            {filterOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box sx={{ display: "flex", gap: 1 }}>
          <Button
            variant="contained"
            sx={{
              bgcolor: "#5cb85c",
              color: "white",
              "&:hover": { bgcolor: "#449d44" },
              fontWeight: "bold",
              borderRadius: "8px",
            }}
            onClick={handleStakeAll}
            disabled={
              filteredNfts.filter((item: any) => !item.stakedConfig).length ===
              0
            }
          >
            Stake All (
            {
              filteredNfts
                .filter((item: any) => !item.stakedConfig)
                ?.slice(0, 100).length
            }
          </Button>
        </Box>

        <FormControl variant="outlined" className="inventory-sort-form">
          <InputLabel id="nft-sort-label" className="inventory-sort-label">
            Sort By
          </InputLabel>
          <Select
            labelId="nft-sort-label"
            id="nft-sort-select"
            value={sortBy}
            onChange={handleSortChange}
            label="Sort By"
            className="inventory-select"
          >
            {sortOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {loading && <CircularProgress color="secondary" size={30} />}
      </Stack>
      <Box
        className="inventory-grid-container"
        sx={{ overflowY: "auto", maxHeight: "75vh" }}
      >
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {loading && nfts.length === 0 ? (
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                textAlign="center"
              >
                Loading your Deer NFTs...
                <CircularProgress size="small" sx={{ ml: 1 }} />
              </Typography>
            </Grid>
          ) : filteredNfts.length > 0 ? (
            filteredNfts.map((nft, index) => (
              <DeerCard
                key={nft.asset_id}
                uri={uri_prefix + nft.data.img}
                nftData={nft}
                name={nft.data.name}
                assetID={nft.asset_id}
                stakedTime={nft.exploration_time}
                onUpgrade={() => openUpgradeModal(nft)} // Open modal on card click, pass NFT data
                onStake={() => handleStake(nft)}
              />
            ))
          ) : (
            !loading && (
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  textAlign="center"
                >
                  No Deer NFTs found in your inventory{" "}
                  {filterType !== "all"
                    ? `of type "${filterOptions
                        .find((opt) => opt.value === filterType)
                        ?.label.toLowerCase()
                        .replace(" deers", "")}"`
                    : ""}
                  .
                </Typography>
              </Grid>
            )
          )}
        </Grid>
      </Box>
      {/* Render UpgradeModal conditionally */}
      <UpgradeModal
        open={isModalOpen}
        onClose={closeUpgradeModal}
        nft={selectedNFTForModal}
      />
    </Box>
  );
};
