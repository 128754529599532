import React, { useMemo } from "react";
import { Box, Button, Typography, Grid, Tooltip } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../store";
import { SetWorldAction } from "../../store/user/actions";
import { useNavigate } from "react-router-dom";
import { DetailedWorldCard } from "./DetailedWorldCard";

interface WorldHeaderProps {
  onWorldSelect: () => void;
  isCompact?: boolean;
}

export const WorldHeader: React.FC<WorldHeaderProps> = ({
  onWorldSelect,
  isCompact = false,
}) => {
  const { loginInfo, gameStatus } = useSelector(
    (state: AppState) => state.userModule
  );
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const currentWorld = gameStatus.world; // Get current world from state

  const handleWorldCardClick = (index: number) => {
    dispatch(SetWorldAction(index));
    navigate("/explore-nfts?worldtype=" + index);
  };

  // Conditionally render worlds based on isCompact prop
  const worldsToDisplay = useMemo(() => {
    if (isCompact && currentWorld) {
      return [currentWorld]; // Display only the current world in compact mode
    } else {
      return [1, 2, 3]; // Display all worlds otherwise
    }
  }, [isCompact, currentWorld]);

  return (
    <Box>
      <Typography variant="h6" color="white" mb={2} textAlign="center">
        {" "}
        {/* Centered title */}
        {isCompact ? "Current World" : "Select Your World"}
      </Typography>
      <Grid container spacing={2} justifyContent="center">
        {gameStatus.reward_info
          ?.filter((item: any, index: any) =>
            loginInfo.open_worlds.includes(item.id)
          )
          .map((item: any, index: number) => {
            return (
              <Grid item key={index} xs={12} sm={6} md={4}>
                <DetailedWorldCard
                  key={index}
                  index={item.id} // Use item.id as index
                  isOpen={loginInfo.open_worlds.includes(index)}
                  cost={parseFloat(item.unlock_fee?.split(" ")?.[0])}
                  rewardInfo={{
                    fees: {
                      og: parseFloat(item.base_fee?.split(" ")?.[0]), // Example fees
                      abyssal: parseFloat(item.base_fee?.split(" ")?.[0]),
                      fawn: parseFloat(item.base_fee?.split(" ")?.[0]) * 5,
                      promo: parseFloat(item.base_fee?.split(" ")?.[0]) * 0.1,
                    }, // Example fees if not in rewardInfo
                    cost_multi: {
                      og: 1,
                      abyssal: 1,
                      fawn: 5,
                      promo: 0.1,
                    },
                    reward_multi: {
                      og: 1,
                      abyssal: 1,
                      fawn: 5,
                      promo: 0.1,
                    },
                    nft_drop_rarity: item.nft_chance == 0 ? "None" : "Enabled",
                    exploration_durations: [item.delay / 3600], // Example durations
                    minReward: parseFloat(item.min_reward).toFixed(4),
                    maxReward: parseFloat(item.max_reward).toFixed(4),
                    baseFee: parseFloat(item.base_fee).toFixed(4),
                    ...item,
                  }}
                  onCardClick={() => handleWorldCardClick(item.id)}
                  isMobile={false}
                  isDetailedView={true} // Indicate detailed view if this card is selected in modal
                />
              </Grid>
            );
          })}
      </Grid>
    </Box>
  );
};
