import React, { useState } from "react";
import { Box, Button, Typography, Modal } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { WorldHeader } from "./WorldHeader";
import { Home } from "./Home";
import { WorldModal } from "./WorldModal";
import { Header } from "../../components/Header";

export const CombinedHomePage = () => {
  const navigate = useNavigate();
  const [isWorldModalOpen, setIsWorldModalOpen] = useState(false);

  const handleOpenWorldModal = () => {
    setIsWorldModalOpen(true);
  };

  const handleCloseWorldModal = () => {
    setIsWorldModalOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          padding: 3,
          backgroundColor: "#070508db",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography variant="h4" component="h2" color="white">
            Your Inventory and Worlds
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenWorldModal}
          >
            Select World
          </Button>
        </Box>

        <WorldHeader onWorldSelect={handleOpenWorldModal} isCompact={true} />

        <Box sx={{ mt: 4 }}>
          <Home />
        </Box>
      </Box>

      <WorldModal open={isWorldModalOpen} onClose={handleCloseWorldModal} />
    </>
  );
};
