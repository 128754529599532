import { Box } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../store";
import { GetLeaderBoard } from "../store/user/actions";

// Define the LeaderboardEntry interface to match the contract structure
interface LeaderboardEntry {
  username: string;
  deer_used: number;
  explore_count: number;
  reward: string; // Using string to represent the asset type
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: "white",
}));

export const LeaderBoard = () => {
  const dispatch = useDispatch<any>();
  const userModule = useSelector((state: AppState) => state.userModule);
  const loginInfo = userModule.loginInfo;
  const gameStatus = userModule.gameStatus;

  const [scores, setScores] = useState<LeaderboardEntry[]>([]);
  const [myScore, setMyScore] = useState<LeaderboardEntry | null>(null);

  useEffect(() => {
    dispatch(GetLeaderBoard());
  }, []);

  useEffect(() => {
    if (gameStatus.leaderboard) {
      // Sort by explore_count which appears to be the main metric based on contract
      // You can change this to deer_used or reward if that's the main ranking criteria
      setScores(
        gameStatus.leaderboard.sort(
          (a: LeaderboardEntry, b: LeaderboardEntry) =>
            parseFloat(b.reward) - parseFloat(a.reward)
        )
      );
    }
    if (gameStatus.myScore) {
      setMyScore(gameStatus.myScore);
    }
  }, [JSON.stringify(gameStatus.leaderboard)]);

  // Function to get user's rank in the leaderboard
  const getUserRank = (username: string): number => {
    const index = scores.findIndex((score) => score.username === username);
    return index !== -1 ? index + 1 : 0;
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundImage: "url(/images/leaderboard_main_bg.png)",
        }}
      >
        <Box sx={{ marginTop: "64px", height: "calc(100vh - 80px)" }}>
          <Box
            sx={{
              p: { xs: 1, sm: 1, md: 10 },
              boxSizing: "border-box",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src="/images/leaderboard_bg.png"
              style={{
                maxWidth: "1150px",
                zIndex: 1,
                position: "absolute",
                height: "80vh",
              }}
            />
            <h1 style={{ color: "white", zIndex: 2, marginTop: "5vh" }}>
              LEADERBOARD{" "}
            </h1>
            <Box
              sx={{
                width: "80%",
                maxWidth: "900px",
                overflowY: "scroll",
                zIndex: 2,
                marginTop: "3vh",
                height: "61vh",
              }}
            >
              <TableContainer component={Paper} sx={{ background: "none" }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center">Rank</StyledTableCell>
                      <StyledTableCell align="center">Account</StyledTableCell>
                      <StyledTableCell align="center">
                        Deers Used
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Explorations
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Earned Antlers
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {scores.map((row: LeaderboardEntry, index: number) => (
                      <TableRow
                        key={row.username}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          backgroundColor:
                            myScore?.username === row.username
                              ? "#4d2e17"
                              : "none",
                        }}
                      >
                        <StyledTableCell
                          component="th"
                          scope="row"
                          align="center"
                        >
                          {index === 0 && (
                            <EmojiEventsIcon sx={{ color: "yellow" }} />
                          )}
                          {index === 1 && (
                            <EmojiEventsIcon sx={{ color: "white" }} />
                          )}
                          {index === 2 && (
                            <EmojiEventsIcon sx={{ color: "chocolate" }} />
                          )}
                          {index + 1}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.username}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.deer_used}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.explore_count}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.reward}
                        </StyledTableCell>
                      </TableRow>
                    ))}
                    {myScore &&
                      !scores.some(
                        (score) => score.username === myScore.username
                      ) && (
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            backgroundColor: "#421d6b",
                          }}
                        >
                          <StyledTableCell
                            component="th"
                            scope="row"
                            align="center"
                          >
                            {getUserRank(myScore.username)}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {myScore.username}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {myScore.deer_used}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {myScore.explore_count}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {myScore.reward}
                          </StyledTableCell>
                        </TableRow>
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
