import {
  Box,
  Button,
  Typography,
  Tab,
  Tabs,
  Grid,
  Divider,
  Paper,
  Alert,
} from "@mui/material";
import { useState, useMemo } from "react";
import TokenSwapInput from "../components/TokenSwapInput";
import { useDispatch, useSelector } from "react-redux";
import {
  BuyGameTokenAction,
  DepositTokenAction,
  WithdrawTokenAction,
  Reload,
} from "../store/user/actions";
import { AppState } from "../store";

// Token type definition for better type safety
interface TokenBalance {
  token: string;
  walletBalance: number;
  inGameBalance: number;
  icon: string;
}

export const Exchange = () => {
  const dispatch = useDispatch<any>();
  const userModule = useSelector((state: AppState) => state.userModule);
  const { loginInfo } = userModule;

  // State management
  const [tabValue, setTabValue] = useState(0);
  const [activeTokenTab, setActiveTokenTab] = useState(0);
  const [swapAmount, setSwapAmount] = useState(0);
  const [depositAmount, setDepositAmount] = useState(0);
  const [withdrawAmount, setWithdrawAmount] = useState(0);

  // Constants
  const GOLD_WAX_RATE = 10;

  // Token data
  const tokens = useMemo<TokenBalance[]>(
    () => [
      {
        token: "GRASS",
        walletBalance: parseFloat(loginInfo?.balance?.grass_balance) || 0,
        inGameBalance: parseFloat(loginInfo?.balance?.game_grass) || 0,
        icon: "/images/token_grass.png",
      },
      {
        token: "ANTLERS",
        walletBalance: parseFloat(loginInfo?.balance?.antler_balance) || 0,
        inGameBalance: parseFloat(loginInfo?.balance?.game_antlers) || 0,
        icon: "/images/token_antlers.png",
      },
      {
        token: "GOLD",
        walletBalance: parseFloat(loginInfo?.balance?.gold_balance) || 0,
        inGameBalance: parseFloat(loginInfo?.balance?.game_gold) || 0,
        icon: "/images/token_gold.png",
      },
    ],
    [loginInfo?.balance]
  );

  // Computed values
  const waxBalance = parseFloat(loginInfo?.balance?.wax_balance) || 0;
  const goldBalance = parseFloat(loginInfo?.balance?.game_gold) || 0;
  const goldReceiveAmount = swapAmount * GOLD_WAX_RATE;
  const activeToken = tokens[activeTokenTab];

  // Input validation
  const validateNumericInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    setter: (value: number) => void
  ) => {
    const value = e.target.value;
    const regex = /^-?\d*\.?\d*$/;
    if (regex.test(value)) {
      setter(value === "" ? 0 : Number(value));
    }
  };

  // Handlers
  const handleSwap = () => {
    if (swapAmount <= 0 || swapAmount > waxBalance) return;
    dispatch(BuyGameTokenAction(swapAmount, goldReceiveAmount));
  };

  const handleDeposit = () => {
    if (depositAmount <= 0 || depositAmount > activeToken.walletBalance) return;
    console.log(`Deposit ${activeToken.token}:`, depositAmount);
    dispatch(DepositTokenAction(depositAmount, activeToken.token));
    setDepositAmount(0);
  };

  const handleWithdraw = () => {
    if (withdrawAmount <= 0 || withdrawAmount > activeToken.inGameBalance)
      return;
    console.log(`Withdraw ${activeToken.token}:`, withdrawAmount);
    dispatch(WithdrawTokenAction(activeToken.token, withdrawAmount));
    dispatch(Reload());
  };

  const isSwapDisabled = swapAmount <= 0 || swapAmount > waxBalance;
  const isDepositDisabled =
    depositAmount <= 0 || depositAmount > activeToken.walletBalance;
  const isWithdrawDisabled =
    withdrawAmount <= 0 || withdrawAmount > activeToken.inGameBalance;

  return (
    <Box
      sx={{
        height: "calc(100vh - 80px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: { xs: 2, md: 4 },
        backgroundImage: "linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7))",
      }}
    >
      <Paper
        elevation={8}
        sx={{
          bgcolor: "rgba(7, 5, 8, 0.92)",
          borderRadius: 3,
          padding: { xs: 3, md: 4 },
          maxWidth: "lg",
          width: "100%",
          border: "1px solid rgba(255,255,255,0.1)",
        }}
      >
        <Grid container spacing={3} direction="column" alignItems="center">
          <Grid item xs={12}>
            <Typography
              variant="h3"
              color="white"
              align="center"
              fontWeight="bold"
              sx={{
                textShadow: "2px 2px 3px rgba(0,0,0,0.7)",
                background: "linear-gradient(90deg, #e0c3fc 0%, #8ec5fc 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Tokens
            </Typography>
          </Grid>

          <Grid item xs={12} width="100%">
            <Tabs
              value={tabValue}
              onChange={(_, newValue) => setTabValue(newValue)}
              variant="fullWidth"
              sx={{
                mb: 3,
                bgcolor: "rgba(0, 0, 0, 0.3)",
                borderRadius: "8px",
                width: "100%",
                maxWidth: 600,
                margin: "0 auto",
              }}
              TabIndicatorProps={{
                style: { backgroundColor: "#8ec5fc" },
              }}
            >
              <Tab
                label="Swap WAX ↔ GOLD"
                sx={{
                  color: "white",
                  fontWeight: "bold",
                  fontSize: "1.1rem",
                  transition: "all 0.3s ease",
                  "&.Mui-selected": { color: "#a7ffeb" },
                }}
              />
              <Tab
                label="Deposit & Withdraw"
                sx={{
                  color: "white",
                  fontWeight: "bold",
                  fontSize: "1.1rem",
                  transition: "all 0.3s ease",
                  "&.Mui-selected": { color: "#a7ffeb" },
                }}
              />
            </Tabs>
          </Grid>

          <Grid item xs={12} sx={{ width: "100%", maxWidth: 600 }}>
            <Box
              sx={{
                padding: { xs: 2, sm: 3 },
                bgcolor: "rgba(2, 90, 183, 0.12)",
                borderRadius: "12px",
                boxShadow: "0px 3px 10px rgba(0,0,0,0.3)",
                border: "1px solid rgba(255,255,255,0.05)",
              }}
            >
              {tabValue === 0 ? (
                // WAX to GOLD Swap Tab
                <Box>
                  <Typography
                    variant="h6"
                    color="white"
                    align="center"
                    sx={{ mb: 1, fontWeight: "bold" }}
                  >
                    WAX to GOLD Swap
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 1,
                      mb: 2,
                    }}
                  >
                    <Box
                      component="span"
                      sx={{
                        bgcolor: "rgba(255,255,255,0.1)",
                        px: 1.5,
                        py: 0.5,
                        borderRadius: 1,
                        fontSize: "0.9rem",
                        color: "#8ec5fc",
                        fontWeight: "bold",
                      }}
                    >
                      1 WAX = {GOLD_WAX_RATE} GOLD
                    </Box>
                  </Box>

                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TokenSwapInput
                        value={swapAmount}
                        onChange={(e: any) =>
                          validateNumericInput(e, setSwapAmount)
                        }
                        token={"WAX"}
                        img={"/images/token_wax.png"}
                        type="sell"
                        balance={`Wallet: ${waxBalance.toLocaleString()} WAX`}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <Box
                        sx={{
                          p: 1,
                          borderRadius: "50%",
                          bgcolor: "rgba(255,255,255,0.1)",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography variant="h6" color="white">
                          ↓
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <TokenSwapInput
                        value={goldReceiveAmount}
                        token={"GOLD"}
                        img={"/images/token_gold.png"}
                        type="receive"
                        balance={`In-Game: ${goldBalance.toLocaleString()} GOLD`}
                      />
                    </Grid>

                    <Grid item xs={12} sx={{ mt: 2 }}>
                      <Button
                        fullWidth
                        color="primary"
                        variant="contained"
                        onClick={handleSwap}
                        disabled={isSwapDisabled}
                        size="large"
                        sx={{
                          fontWeight: "bold",
                          py: 1.5,
                          background: !isSwapDisabled
                            ? "linear-gradient(90deg, #4facfe 0%, #00f2fe 100%)"
                            : "rgba(0,0,0,0.3)",
                          transition: "all 0.3s ease",
                          "&:hover": {
                            background: !isSwapDisabled
                              ? "linear-gradient(90deg, #00f2fe 0%, #4facfe 100%)"
                              : "rgba(0,0,0,0.3)",
                          },
                        }}
                      >
                        SWAP WAX FOR GOLD
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              ) : (
                <Box>
                  <Typography
                    variant="h6"
                    color="white"
                    align="center"
                    sx={{ fontWeight: "bold", mb: 2 }}
                  >
                    Deposit & Withdraw Tokens
                  </Typography>

                  <Box sx={{ mb: 3 }}>
                    <Tabs
                      value={activeTokenTab}
                      onChange={(_, newValue) => {
                        setActiveTokenTab(newValue);
                        setDepositAmount(0);
                        setWithdrawAmount(0);
                      }}
                      variant="fullWidth"
                      sx={{
                        bgcolor: "rgba(0, 0, 0, 0.3)",
                        borderRadius: "8px",
                        mb: 3,
                      }}
                      TabIndicatorProps={{
                        style: { backgroundColor: "#a7ffeb" },
                      }}
                    >
                      {tokens.map((token, index) => (
                        <Tab
                          key={token.token}
                          label={token.token}
                          sx={{
                            color: "white",
                            fontWeight: "bold",
                            transition: "all 0.3s ease",
                            "&.Mui-selected": { color: "#a7ffeb" },
                          }}
                        />
                      ))}
                    </Tabs>
                  </Box>

                  <Box
                    sx={{
                      bgcolor: "rgba(30, 30, 30, 0.5)",
                      borderRadius: "10px",
                      p: 3,
                      border: "1px solid rgba(255,255,255,0.05)",
                    }}
                  >
                    {/* Deposit Section */}
                    <Typography
                      variant="subtitle1"
                      color="white"
                      sx={{ fontWeight: "bold", mb: 1 }}
                    >
                      Deposit {activeToken.token}
                    </Typography>

                    <TokenSwapInput
                      value={depositAmount}
                      onChange={(e: any) =>
                        validateNumericInput(e, setDepositAmount)
                      }
                      token={activeToken.token}
                      img={activeToken.icon}
                      type="deposit"
                      balance={`Wallet: ${activeToken.walletBalance.toLocaleString()} ${
                        activeToken.token
                      }`}
                    />

                    <Button
                      variant="contained"
                      onClick={handleDeposit}
                      disabled={isDepositDisabled}
                      fullWidth
                      sx={{
                        py: 1,
                        mt: 2,
                        mb: 3,
                        background: !isDepositDisabled
                          ? "linear-gradient(90deg, #4facfe 0%, #00f2fe 100%)"
                          : "rgba(0,0,0,0.3)",
                        transition: "all 0.3s ease",
                        "&:hover": {
                          background: !isDepositDisabled
                            ? "linear-gradient(90deg, #00f2fe 0%, #4facfe 100%)"
                            : "rgba(0,0,0,0.3)",
                        },
                      }}
                    >
                      Deposit {activeToken.token}
                    </Button>

                    <Divider sx={{ my: 3, bgcolor: "rgba(255,255,255,0.1)" }} />

                    {/* Withdraw Section */}
                    <Typography
                      variant="subtitle1"
                      color="white"
                      sx={{ fontWeight: "bold", mb: 1 }}
                    >
                      Withdraw {activeToken.token}
                    </Typography>

                    <TokenSwapInput
                      value={withdrawAmount}
                      onChange={(e: any) =>
                        validateNumericInput(e, setWithdrawAmount)
                      }
                      token={activeToken.token}
                      img={activeToken.icon}
                      type="withdraw"
                      balance={`In-Game: ${activeToken.inGameBalance.toLocaleString()} ${
                        activeToken.token
                      }`}
                    />

                    <Button
                      variant="contained"
                      onClick={handleWithdraw}
                      disabled={isWithdrawDisabled}
                      fullWidth
                      sx={{
                        py: 1,
                        mt: 2,
                        background: !isWithdrawDisabled
                          ? "linear-gradient(90deg, #4facfe 0%, #00f2fe 100%)"
                          : "rgba(0,0,0,0.3)",
                        transition: "all 0.3s ease",
                        "&:hover": {
                          background: !isWithdrawDisabled
                            ? "linear-gradient(90deg, #00f2fe 0%, #4facfe 100%)"
                            : "rgba(0,0,0,0.3)",
                        },
                      }}
                    >
                      Withdraw {activeToken.token}
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default Exchange;
