import { Box, Typography, ClickAwayListener } from "@mui/material";
import { useSelector } from "react-redux";
import { AppState } from "../store";

export const BalanceDropdown = ({ onClose }: { onClose: () => void }) => {
  const userModule = useSelector((state: AppState) => state.userModule);
  const loginInfo = userModule.loginInfo;

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "100%",
          right: 0,
          mt: 1,
          minWidth: 250,
          backgroundColor: "#1a1a1a",
          borderRadius: "8px",
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.5)",
          p: 2,
          zIndex: 9999,
        }}
      >
        <Typography variant="h6" sx={{ color: "#9a7fff", mb: 1 }}>
          Your Balances
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <BalanceItem
            label="WAX"
            value={parseFloat(loginInfo?.balance?.wax_balance)}
          />
          <BalanceItem
            label="GRASS"
            value={parseFloat(loginInfo?.balance?.game_grass)}
          />
          <BalanceItem
            label="GOLD"
            value={parseFloat(loginInfo?.balance?.game_gold)}
          />
          <BalanceItem
            label="XP"
            value={parseFloat(loginInfo?.balance?.game_xp)}
          />
          <BalanceItem
            label="ANTLERS"
            value={parseFloat(loginInfo?.balance?.game_antlers)}
          />{" "}
        </Box>
      </Box>
    </ClickAwayListener>
  );
};

const BalanceItem = ({ label, value }: { label: string; value: number }) => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    }}
  >
    <Typography variant="body1" sx={{ color: "#fff" }}>
      {label}
    </Typography>
    <Typography variant="body1" sx={{ color: "aquamarine" }}>
      {isNaN(value) ? "0.0000" : value.toFixed(4)}
    </Typography>
  </Box>
);
