import React, { useState, useRef, useEffect } from "react";
import "./card.css";
import {
  Box,
  Button,
  Grid,
  Typography,
  Tooltip,
  Zoom,
  Chip,
} from "@mui/material";
import { GetTimeString } from "../../utiles";

export interface DeerCardProps {
  uri: any;
  nftData: any;
  name: string;
  assetID: string;
  stakedTime?: any;
  isSelected?: boolean;
  onClick?: any;
  onClaim?: any;
  onStake?: any;
  onUpgrade?: any;
}

const deerTypeStyles = {
  og: {
    backgroundGradient:
      "radial-gradient(circle,rgb(4, 6, 49) 0%,rgb(67, 10, 52) 100%)",
  },
  fawn: {
    backgroundGradient: "radial-gradient(circle, #8B4513 0%, #654321 100%)",
  },
  abyssal: {
    backgroundGradient:
      "radial-gradient(circle, #5c0067 0%,rgb(8, 101, 119) 100%)",
  },
  promo: {
    backgroundGradient:
      "radial-gradient(circle, rgb(97, 93, 55) 0%, rgb(63, 41, 12) 100%)",
  },
  default: {
    backgroundGradient: "radial-gradient(circle, #333333 0%, #111111 100%)",
  },
};

const DeerCard: React.FC<DeerCardProps> = ({
  uri,
  nftData,
  name,
  assetID,
  stakedTime,
  isSelected,
  onClick,
  onClaim = null,
  onStake = null,
  onUpgrade = null,
}) => {
  const deerType =
    nftData?.schema?.schema_name === "og.deer"
      ? "og"
      : nftData?.schema?.schema_name === "fawn"
      ? "fawn"
      : nftData?.schema?.schema_name === "abyssal"
      ? "abyssal"
      : nftData?.schema?.schema_name === "promo"
      ? "promo"
      : "default";

  const currentDeerStyle = deerTypeStyles[deerType] || deerTypeStyles.default;

  const box_style = {
    display: "flex",
    position: "relative",
    justifyContent: "space-between",
    flexDirection: "column",
    margin: "auto",
    height: "100%",
    maxWidth: "220px",
    width: "95%",
    bgcolor: "#1a1f3c",
    borderRadius: 3,
    border: "2px solid " + (isSelected ? "#1fff57" : "transparent"),
    backgroundImage: currentDeerStyle.backgroundGradient,
    boxShadow: isSelected
      ? "0 0 10px #1fff57, 0 0 20px #1fff57"
      : "0 2px 5px rgba(0,0,0,0.3)",
    cursor: "pointer",
    overflow: "hidden",
  };

  const [isHovered, setIsHovered] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const cardRef = useRef(null);

  const [uTime, setUTime] = useState(0);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const startTimer = () => {
    if (timerRef.current == null) {
      timerRef.current = setInterval(() => {
        setUTime((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(timerRef.current!);
            timerRef.current = null;
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }
  };

  useEffect(() => {
    if (stakedTime && stakedTime > 0) {
      clearInterval(timerRef.current!);
      timerRef.current = null;

      const currentUnixTime = Math.floor(new Date().getTime() / 1000);
      let delayTime = stakedTime - currentUnixTime;

      if (delayTime > 0) {
        setUTime(delayTime);
        startTimer();
      }
    }

    return () => {
      clearInterval(timerRef.current!);
      timerRef.current = null;
    };
  }, [stakedTime]);

  const onClickNFT = (id: any) => {
    if (onClick && getDeerStatus() === "Idle") {
      onClick(id);
    }
  };

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (isHovered) {
      timeoutId = setTimeout(() => {
        setTooltipOpen(true);
      }, 500);
    } else {
      setTooltipOpen(false);
    }

    return () => clearTimeout(timeoutId);
  }, [isHovered]);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const renderAttributeItem = (label: string, value: any) => {
    if (!value) return null;
    return (
      <Typography
        variant="body2"
        sx={{
          color: "white",
          display: "flex",
          justifyContent: "space-between",
          borderBottom: "1px solid #616161",
          pb: 0.5,
          mb: 0.5,
        }}
      >
        <span style={{ color: "#ccc" }}> {label}: </span>
        <span> {value} </span>
      </Typography>
    );
  };

  const getDeerStatus = () => {
    const currentUnixTime = Math.floor(Date.now() / 1000);
    if (!nftData?.stakedConfig) return "Unstaked";
    else if (nftData?.upgrade_end_at > currentUnixTime) {
      return "Upgrading";
    } else if (nftData?.stakedConfig?.current_explore > 0) {
      return "Exploring #" + nftData?.stakedConfig?.current_explore;
    } else if (nftData?.stakedConfig?.last_used + 86400 > currentUnixTime) {
      return "Blocked";
    } else {
      return "Idle";
    }
  };

  const deerStatus = getDeerStatus();
  var rewardMultiplier = nftData?.deer_config?.reward_multi || 1.0;

  const extra_multi = nftData?.deer_config?.level_multi || [];
  const level = nftData?.level || 1;
  if (extra_multi.find((a: any) => a.key == level)) {
    rewardMultiplier +=
      extra_multi.find((a: any) => a.key == level).value / 100 - 1;
  }

  //fix to 2 decimals
  return (
    <Grid item xl={2} md={2} sm={3} xs={4} sx={{ mb: 3 }}>
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: "#1a203c",
              "& .MuiTooltip-arrow": {
                color: "#1a203c",
              },
              p: 2,
              maxWidth: "300px",
              maxHeight: "400px",
              overflowY: "auto",
            },
          },
        }}
        open={tooltipOpen}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        placement="right-start"
        arrow
        TransitionComponent={Zoom}
        title={
          <Box>
            {renderAttributeItem("Level", nftData?.level)}
            {renderAttributeItem("Asset ID", nftData?.asset_id)}
            {renderAttributeItem(
              "Type",
              deerType.charAt(0).toUpperCase() + deerType.slice(1)
            )}
            {renderAttributeItem("Rarity", nftData?.data?.Rarity)}
            {renderAttributeItem("Base", nftData?.data?.Base)}
            {renderAttributeItem("Eyes", nftData?.data?.Eyes)}
            {renderAttributeItem("Cloth", nftData?.data?.Cloth)}
            {renderAttributeItem("Horns", nftData?.data?.Horns)}
            {renderAttributeItem("Mouth", nftData?.data?.Mouth)}
            {renderAttributeItem("Wings", nftData?.data?.Wings)}
            {renderAttributeItem("Earring", nftData?.data?.Earring)}
            {renderAttributeItem("Glasses", nftData?.data?.Glasses)}
            {renderAttributeItem("Necklace", nftData?.data?.Necklace)}
            {renderAttributeItem("Background", nftData?.data?.Background)}
            <Box sx={{ mt: 1 }}> </Box>
          </Box>
        }
      >
        <Box
          sx={box_style}
          ref={cardRef}
          onClick={() => onClickNFT(assetID)}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              p: 1,
            }}
          >
            <Box>
              <Chip
                label={deerStatus}
                color={
                  deerStatus === "Upgrading"
                    ? "warning"
                    : deerStatus.includes("Exploring")
                    ? "success"
                    : deerStatus === "Unstaked"
                    ? "error"
                    : "info"
                }
                size="small"
                sx={{ mr: 1 }}
              />
            </Box>
            <Typography
              variant="body2"
              sx={{ fontSize: "12px", color: "white", textAlign: "right" }}
            >
              {deerStatus.includes("Exploring")
                ? uTime > 0
                  ? GetTimeString(uTime)
                  : "Unclaimed"
                : deerStatus === "Upgrading"
                ? "Leveling"
                : "-"}
            </Typography>
          </Box>

          <Box sx={{ width: "95%", textAlign: "center", m: "auto", mb: 1 }}>
            <img
              style={{
                width: "100%",
                borderRadius: "8px",
                display: "block",
                margin: "0 auto",
              }}
              src={uri}
              alt={name}
            />
          </Box>

          <Box sx={{ p: 1, pt: 0 }}>
            <Typography
              variant="subtitle1"
              sx={{
                color: "white",
                fontWeight: "bold",
                textAlign: "center",
                mb: 0.5,
                fontSize: "0.85rem",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {name}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "#ddd",
                textAlign: "center",
                fontStyle: "italic",
                mb: 0.5,
              }}
            >
              Level {nftData.level}
            </Typography>
            <Box
              sx={{ display: "flex", justifyContent: "space-around", mt: 1 }}
            >
              <Typography
                variant="body2"
                sx={{
                  color: "#ddd",
                  textAlign: "center",
                  fontStyle: "italic",
                  mb: 0.5,
                }}
              >
                Cost {parseFloat(nftData.deer_config?.cost_multi)} x
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: "#ddd",
                  textAlign: "center",
                  fontStyle: "italic",
                  mb: 0.5,
                }}
              >
                Reward {parseFloat(rewardMultiplier)} x
              </Typography>
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-around", mt: 1 }}
            >
              {onUpgrade && nftData.stakedConfig && (
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    onUpgrade();
                  }}
                  variant="contained"
                  color="warning"
                  size="small"
                  sx={{ fontSize: "0.7rem", minWidth: "auto", px: 1 }}
                >
                  Upgrade
                </Button>
              )}

              {onStake && !nftData.stakedConfig && (
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    onStake();
                  }}
                  variant="contained"
                  color="success"
                  size="small"
                  sx={{ fontSize: "0.7rem", minWidth: "auto", px: 1 }}
                >
                  Stake
                </Button>
              )}
              <Button
                href={`https://wax.atomichub.io/explorer/asset/wax-mainnet/${assetID}`}
                target="_blank"
                rel="noreferrer"
                variant="contained"
                color="primary"
                size="small"
                onClick={(e) => e.stopPropagation()}
                sx={{ fontSize: "0.7rem", minWidth: "auto", px: 1 }}
              >
                View
              </Button>
            </Box>
          </Box>
        </Box>
      </Tooltip>
    </Grid>
  );
};

export default DeerCard;
