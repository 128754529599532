import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  Chip,
  Divider,
  Paper,
  Alert,
  LinearProgress,
  IconButton,
  Tooltip,
  Stack,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store";
import { GetTimeString } from "../../utiles";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import PersonIcon from "@mui/icons-material/Person";
import PublicIcon from "@mui/icons-material/Public";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { claimExploration, Reload } from "../../store/user/actions";

// Helper function to claim all explorations
const claimAllExplorations =
  (exploreIds: number[]) => async (dispatch: any) => {
    try {
      const results = await Promise.all(
        exploreIds.map((id) => dispatch(claimExploration(id)))
      );
      return results.every((result) => result);
    } catch (error) {
      console.error("Claim all explorations error:", error);
      return false;
    }
  };

export const ClaimExplorations = () => {
  const dispatch = useDispatch<any>();
  const userModule = useSelector((state: AppState) => state.userModule);
  // Add null checks and provide default values
  const { loginInfo = {} as any, gameStatus = {} } = userModule || {};

  //Hello copi
  const [explorations, setExplorations] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [claimableExplorations, setClaimableExplorations] = useState<number[]>(
    []
  );
  const [showInstructions, setShowInstructions] = useState(false);

  // URI prefix for NFT images
  const URI_PREFIX = "https://ipfs.io/ipfs/";

  // Fetch explorations from the explorations table
  const fetchExplorations = useCallback(async () => {
    setIsLoading(true);
    try {
      // Add null check for gameStatus
      const fetchedExplorations = gameStatus?.explorations || [];

      setExplorations(fetchedExplorations);

      // Check which explorations are ready to claim
      const currentTime = Math.floor(Date.now() / 1000);
      const claimable = fetchedExplorations
        .filter((exp: any) => exp && exp.end_time <= currentTime)
        .map((exp: any) => exp.id);

      setClaimableExplorations(claimable);
    } catch (error) {
      console.error("Error fetching explorations:", error);
      toast.error("Failed to load explorations");
    } finally {
      setIsLoading(false);
    }
  }, [gameStatus]);

  useEffect(() => {
    fetchExplorations();
  }, [fetchExplorations]);

  // Handle claiming a single exploration
  const handleClaimExploration = async (exploreId: number) => {
    try {
      const success = await dispatch(claimExploration(exploreId));
      if (success) {
        toast.success(`Successfully claimed exploration #${exploreId}`);
      } else {
        toast.error(`Failed to claim exploration #${exploreId}`);
      }
    } catch (error) {
      console.error("Error claiming exploration:", error);
      toast.error("An error occurred while claiming");
    }
  };

  // Handle claiming all explorations
  const handleClaimAll = async () => {
    if (claimableExplorations.length === 0) {
      toast.info("No explorations ready to claim");
      return;
    }

    try {
      const success = await dispatch(
        claimAllExplorations(claimableExplorations)
      );
      if (success) {
        toast.success(
          `Successfully claimed ${claimableExplorations.length} explorations`
        );
        dispatch(Reload()); // Refresh user data
      } else {
        toast.error("Failed to claim some or all explorations");
      }
    } catch (error) {
      console.error("Error claiming all explorations:", error);
      toast.error("An error occurred while claiming");
    }
  };

  // Find NFT data by asset ID - add null checks
  const findNftData = (assetId: string) => {
    if (!loginInfo || !loginInfo.assets) return null;

    return (
      loginInfo.assets.find(
        (asset: any) => asset && String(asset.asset_id) === String(assetId)
      ) || null
    );
  };

  // Calculate expected rewards based on deer multipliers
  const calculateExpectedRewards = (exploration: any, worldInfo: any) => {
    if (!worldInfo || !exploration || !loginInfo?.assets) {
      return { minReward: "0.0000", maxReward: "0.0000" };
    }

    // Parse base min and max rewards from the world info
    const baseMinReward = parseFloat(worldInfo.min_reward) || 0;
    const baseMaxReward = parseFloat(worldInfo.max_reward) || 0;

    let totalMinReward = 0;
    let totalMaxReward = 0;

    // Process each deer's reward multiplier individually, with null checks
    if (Array.isArray(exploration.asset_ids)) {
      exploration.asset_ids.forEach((assetId: string) => {
        const nftData = findNftData(assetId);
        // Get the reward multiplier or default to 1 if not found
        var rewardMultiplier = nftData?.deer_config?.reward_multi || 1;
        const deerLevelMulti = nftData?.deer_config?.level_multi || [];
        const level = nftData?.level || 1;
        if (deerLevelMulti.find((a: any) => a.key == level)) {
          rewardMultiplier +=
            deerLevelMulti.find((a: any) => a.key == level).value / 100 - 1;
        }

        // Add this deer's contribution to the total
        totalMinReward += baseMinReward * rewardMultiplier;
        totalMaxReward += baseMaxReward * rewardMultiplier;
      });
    }

    return {
      minReward: totalMinReward.toFixed(4),
      maxReward: totalMaxReward.toFixed(4),
    };
  };

  // Instructions panel content
  const instructionsContent = (
    <Paper sx={{ p: 3, bgcolor: "rgba(0, 0, 0, 0.7)", color: "white", mb: 3 }}>
      <Typography variant="h5" gutterBottom>
        How to Claim Explorations
      </Typography>
      <Divider sx={{ mb: 2, bgcolor: "rgba(255, 255, 255, 0.2)" }} />
      <Typography variant="body1" paragraph>
        When your Deer NFTs return from exploration, you can claim your rewards
        here:
      </Typography>
      <ol style={{ paddingLeft: "1.5rem" }}>
        <li>
          <Typography variant="body1" paragraph>
            Wait until the exploration timer reaches zero
          </Typography>
        </li>
        <li>
          <Typography variant="body1" paragraph>
            Click the "Claim" button for individual explorations or "Claim All"
            for all completed explorations
          </Typography>
        </li>
        <li>
          <Typography variant="body1" paragraph>
            Your ANTLERS rewards will be sent to your in -game balance
          </Typography>
        </li>
        <li>
          <Typography variant="body1" paragraph>
            If you're lucky, you might also receive special NFT drops!
          </Typography>
        </li>
      </ol>
      <Typography variant="body2" sx={{ fontStyle: "italic", mt: 2 }}>
        Note: Claim times depend on the world where your Deer was exploring.
      </Typography>
      <Button
        variant="contained"
        sx={{ mt: 2 }}
        onClick={() => setShowInstructions(false)}
      >
        Got it!
      </Button>
    </Paper>
  );

  // Guard against undefined gameStatus
  const rewardInfo = gameStatus?.reward_info || [];

  return (
    <Box sx={{ p: 3, maxWidth: "1400px", mx: "auto" }}>
      {/* Title Section */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 3,
        }}
      >
        <Typography variant="h4" component="h1" color="white">
          Claim Explorations
        </Typography>
        <Tooltip title="View Instructions">
          <IconButton
            onClick={() => setShowInstructions(!showInstructions)}
            sx={{ color: "aquamarine" }}
          >
            <HelpOutlineIcon />
          </IconButton>
        </Tooltip>
      </Box>

      {/* Instructions Panel (Togglable) */}
      {showInstructions && instructionsContent}

      {/* Action Buttons */}
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
        <Button
          variant="contained"
          color="primary"
          disabled={claimableExplorations.length === 0}
          onClick={handleClaimAll}
        >
          Claim All({claimableExplorations.length})
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={fetchExplorations}
        >
          Refresh
        </Button>
      </Box>

      {/* Loading State */}
      {isLoading && (
        <Box sx={{ width: "100%", mb: 3 }}>
          <LinearProgress color="secondary" />
        </Box>
      )}

      {/* Empty State */}
      {!isLoading && explorations.length === 0 && (
        <Alert severity="info" sx={{ mb: 3 }}>
          You don't have any active or completed explorations. Visit the Explore
          page to send your Deer NFTs on exploration.
        </Alert>
      )}

      {/* Explorations Grid */}
      <Grid container spacing={3}>
        {Array.isArray(explorations) &&
          explorations.map((explore) => {
            if (!explore) return null;

            const currentTime = Math.floor(Date.now() / 1000);
            const isCompleted = explore.end_time <= currentTime;
            const timeRemaining = Math.max(0, explore.end_time - currentTime);
            const worldInfo = rewardInfo.find(
              (w: any) => w && w.id === explore.world_id
            );

            // Calculate expected rewards with deer multipliers
            const { minReward, maxReward } = calculateExpectedRewards(
              explore,
              worldInfo
            );

            return (
              <Grid item xs={12} sm={6} md={4} key={explore.id}>
                <Card
                  sx={{
                    bgcolor: "rgba(0, 0, 0, 0.7)",
                    border: isCompleted
                      ? "1px solid #4caf50"
                      : "1px solid #ff9800",
                    borderRadius: 2,
                    overflow: "hidden",
                    transition: "transform 0.3s",
                    "&:hover": {
                      transform: "translateY(-5px)",
                    },
                  }}
                >
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mb: 1,
                      }}
                    >
                      <Typography variant="h6" color="white">
                        Exploration #{explore.id}
                      </Typography>
                      <Chip
                        label={isCompleted ? "Ready to Claim" : "In Progress"}
                        color={isCompleted ? "success" : "warning"}
                        size="small"
                      />
                    </Box>

                    <Stack
                      direction="row"
                      spacing={2}
                      alignItems="center"
                      sx={{ mb: 1 }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <PublicIcon
                          sx={{ fontSize: 16, color: "#aaa", mr: 0.5 }}
                        />
                        <Typography variant="body2" color="#aaa">
                          World {explore.world_id}
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <PersonIcon
                          sx={{ fontSize: 16, color: "#aaa", mr: 0.5 }}
                        />
                        <Typography variant="body2" color="#aaa">
                          {explore.owner}
                        </Typography>
                      </Box>
                    </Stack>

                    <Typography variant="body2" color="#aaa" gutterBottom>
                      Started:{" "}
                      {new Date(explore.start_time * 1000).toLocaleString()}
                    </Typography>

                    <Divider
                      sx={{ my: 1, bgcolor: "rgba(255, 255, 255, 0.1)" }}
                    />

                    {/* Reward Preview - Updated to use calculated values based on multipliers */}
                    {worldInfo && (
                      <Box sx={{ my: 1 }}>
                        <Typography variant="body2" color="white">
                          Expected Reward: {minReward} - {maxReward} ANTLERS
                        </Typography>
                        {worldInfo.nft_chance > 0 && (
                          <Typography variant="body2" color="#ffeb3b">
                            NFT Drop Chance: {worldInfo.nft_chance / 100}%
                          </Typography>
                        )}
                      </Box>
                    )}

                    <Divider
                      sx={{ my: 1, bgcolor: "rgba(255, 255, 255, 0.1)" }}
                    />

                    {/* Deer NFTs with reward multipliers */}
                    <Typography variant="body2" color="white" sx={{ mb: 1 }}>
                      Deer NFTs({explore.asset_ids?.length || 0}):
                    </Typography>

                    <Box
                      sx={{ display: "flex", flexWrap: "wrap", gap: 1, mb: 2 }}
                    >
                      {Array.isArray(explore.asset_ids) &&
                        explore.asset_ids.map((assetId: string) => {
                          const nftData = findNftData(assetId);
                          var rewardMultiplier =
                            nftData?.deer_config?.reward_multi || 1;

                          const extra_multi =
                            nftData?.deer_config?.level_multi || [];
                          const level = nftData?.level || 1;
                          if (extra_multi.find((a: any) => a.key == level)) {
                            rewardMultiplier +=
                              extra_multi.find((a: any) => a.key == level)
                                .value /
                                100 -
                              1;
                          }

                          return (
                            <Tooltip
                              key={assetId}
                              title={
                                nftData
                                  ? `${
                                      nftData.data?.name || "Unknown"
                                    } (Level ${
                                      nftData.level || "?"
                                    }, ${rewardMultiplier}x Rewards)`
                                  : `Asset #${assetId}`
                              }
                            >
                              <Box sx={{ position: "relative" }}>
                                <Box
                                  component="img"
                                  src={
                                    nftData && nftData.data?.img
                                      ? `${URI_PREFIX}${nftData.data.img}`
                                      : "/images/placeholder.png"
                                  }
                                  alt={
                                    nftData && nftData.data?.name
                                      ? nftData.data.name
                                      : `Asset #${assetId}`
                                  }
                                  sx={{
                                    width: 50,
                                    height: 50,
                                    borderRadius: 1,
                                    border: "1px solid #666",
                                  }}
                                />
                                {rewardMultiplier > 1 && (
                                  <Chip
                                    label={`${rewardMultiplier}x`}
                                    size="small"
                                    color="secondary"
                                    sx={{
                                      position: "absolute",
                                      top: -5,
                                      right: -5,
                                      height: 20,
                                      fontSize: "0.6rem",
                                      px: 0.5,
                                    }}
                                  />
                                )}
                              </Box>
                            </Tooltip>
                          );
                        })}
                    </Box>

                    {/* Progress or Claim Button */}
                    {isCompleted ? (
                      <Button
                        variant="contained"
                        color="success"
                        fullWidth
                        onClick={() => handleClaimExploration(explore.id)}
                      >
                        Claim Rewards
                      </Button>
                    ) : (
                      <Box sx={{ width: "100%" }}>
                        <Typography
                          variant="body2"
                          color="white"
                          align="center"
                          gutterBottom
                        >
                          Completing in: {GetTimeString(timeRemaining)}
                        </Typography>
                        <LinearProgress
                          variant="determinate"
                          value={
                            ((explore.end_time -
                              explore.start_time -
                              timeRemaining) /
                              (explore.end_time - explore.start_time)) *
                            100
                          }
                          color="secondary"
                        />
                      </Box>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
      </Grid>
    </Box>
  );
};

export default ClaimExplorations;
