import {
  Box,
  Grid,
  Button,
  Typography,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  Modal,
  Fade,
} from "@mui/material";
import { useEffect, useState } from "react";
import { StakingPoolCard } from "./StakingPoolCard";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../store";
import { fetchStakingData } from "../../store/user/loader";
import {
  ClaimStakePool,
  StakePool,
  UnstakePool,
} from "../../store/user/actions";
import { InstructionsModal } from "./InstructionsModal";
interface Pool {
  id: number;
  min_stake: string;
  total_staked: string;
  stake_delay: number;
  base_apr: any;
  monthly_increase: number;
  is_open: boolean;
  reward_type: string;
}

interface Staker {
  id: number;
  pool_id: number;
  username: string;
  staked_amount: string;
  pending_rewards: string;
  end_time: number;
  start_time: number;
  last_reward_update: number;
}
export const Staking = () => {
  const dispatch = useDispatch<any>();
  const userModule = useSelector((state: AppState) => state.userModule);
  const { loginInfo, gameStatus } = userModule;

  const [pools, setPools] = useState<Pool[]>([]);
  const [stakerInfo, setStakerInfo] = useState<Staker | null>(null);
  const [selectedPool, setSelectedPool] = useState<Pool | null>(null);
  const [stakeAmount, setStakeAmount] = useState("");
  const [isStaking, setIsStaking] = useState(false);
  const [showStakeDialog, setShowStakeDialog] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [stakers, setAllStakers] = useState<Staker[]>([]);
  const [selectedPoolId, setSelectedPoolId] = useState<number | null>(null);

  const [showInstructions, setShowInstructions] = useState(false);

  useEffect(() => {
    if (loginInfo?.account) {
      refreshStakingData();
    }
  }, [loginInfo?.account]);

  const handlePoolSelection = (event: any) => {
    setSelectedPoolId(event.target.value as number);
  };

  const filteredStakers = selectedPoolId
    ? stakers
        .filter((staker) => staker.pool_id === selectedPoolId)
        ?.sort(
          (a, b) =>
            parseFloat(b.staked_amount?.split(" ")?.[0]) -
            parseFloat(a.staked_amount?.split(" ")?.[0])
        )
    : stakers;

  const refreshStakingData = async () => {
    if (loginInfo?.account) {
      const data = await fetchStakingData();
      console.log(data);
      setPools(data.pool_info);
      setAllStakers(data.staking_pool);
      var foundInStakingPool = data.staking_pool?.find(
        (p: any) => p.username === loginInfo.account
      );
      setStakerInfo(foundInStakingPool || null);
    }
  };

  const handleStake = async (pool: Pool) => {
    setSelectedPool(pool);
    setShowStakeDialog(true);
  };

  const handleConfirmStake = async () => {
    if (!selectedPool || !loginInfo?.account) return;

    setIsStaking(true);
    const selectedID = selectedPool.id || 0;
    try {
      const action = {
        account: "waxdeer",
        name: "transfer",
        authorization: [
          {
            actor: loginInfo.account,
            permission: "active",
          },
        ],
        data: {
          from: loginInfo.account,
          to: "waxdeer",
          quantity: stakeAmount,
          memo: `stake:${selectedPool.id}`,
        },
      };

      await dispatch(StakePool(selectedID, parseFloat(stakeAmount)));

      setShowStakeDialog(false);
    } catch (error) {
      console.error("Staking failed:", error);
    } finally {
      setIsStaking(false);
    }
  };

  const handleClaim = async (stakeId: number) => {
    if (!loginInfo?.account) return;

    try {
      const action = {
        account: "waxdeer",
        name: "claim",
        authorization: [
          {
            actor: loginInfo.account,
            permission: "active",
          },
        ],
        data: {
          stake_id: stakeId,
        },
      };

      //   await loginInfo.wax.api.transact(
      //     {
      //       actions: [action],
      //     },
      //     {
      //       blocksBehind: 3,
      //       expireSeconds: 30,
      //     }
      //   );ClaimStakePool
      await dispatch(ClaimStakePool(stakeId));

      await refreshStakingData();
    } catch (error) {
      console.error("Claim failed:", error);
    }
  };
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };
  const handleUnstake = async (stakeId: number) => {
    if (!loginInfo?.account) return;

    try {
      const action = {
        account: "waxdeer",
        name: "unstake",
        authorization: [
          {
            actor: loginInfo.account,
            permission: "active",
          },
        ],
        data: {
          stake_id: stakeId,
        },
      };

      //   await loginInfo.wax.api.transact(
      //     {
      //       actions: [action],
      //     },
      //     {
      //       blocksBehind: 3,
      //       expireSeconds: 30,
      //     }
      //   );
      await dispatch(UnstakePool(stakeId));

      await refreshStakingData();
    } catch (error) {
      console.error("Unstake failed:", error);
    }
  };

  return (
    <Box sx={{ height: "calc(100vh - 80px)" }}>
      <InstructionsModal
        showInstructions={showInstructions}
        setShowInstructions={setShowInstructions}
      />
      <Box
        sx={{
          p: { xs: 1, sm: 1, md: 10 },
          boxSizing: "border-box",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
        }}
      >
        <h1 style={{ color: "#2203c1", position: "relative" }}>
          <span
            style={{
              position: "relative",
              zIndex: "100",
              color: "white",
              fontSize: "24px",
            }}
          >
            STAKING
          </span>
          <img
            src="/images/title_bg.png"
            style={{
              position: "absolute",
              top: 0,
              left: "50%",
              width: "200px",
              transform: "translate(-50%, -4px)",
            }}
          />
        </h1>

        <Box
          sx={{
            width: "100%",
            height: "90%",
            maxWidth: "1400px",
            p: 2,
            boxSizing: "border-box",
            backgroundColor: "#1a051d",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            sx={{
              marginBottom: 2,
              width: "100%",
              backgroundColor: "#1a051d",
              color: "white ",
            }}
            centered
          >
            <Tab
              label="Pools"
              sx={{
                color: "white ",
              }}
            />
            <Tab
              label="Leaderboards"
              sx={{
                color: "white ",
              }}
            />
          </Tabs>{" "}
          {activeTab === 0 && (
            <Box
              sx={{
                width: "100%",
                height: "90%",
                margin: "auto",
                borderRadius: "8px",
                position: "relative",
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  position: "absolute",
                  top: "10px",
                  left: "10px",
                  backgroundColor: "#777a11",
                  color: "#fff",
                }}
                onClick={() => setShowInstructions(true)}
              >
                How to Stake?
              </Button>{" "}
              <Box sx={{ overflowY: "auto", mt: 2, height: "100%" }}>
                <Grid
                  container
                  sx={{
                    p: 2,
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  {pools?.map((pool: Pool | any) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      key={pool.id}
                      sx={{ p: 1, gap: 2 }}
                    >
                      <StakingPoolCard
                        pool={pool}
                        stakerInfo={stakerInfo}
                        onStake={() => handleStake(pool)}
                        onClaim={() => stakerInfo && handleClaim(stakerInfo.id)}
                        onUnstake={() =>
                          stakerInfo && handleUnstake(stakerInfo.id)
                        }
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>
          )}
          {activeTab === 1 && (
            <Box
              sx={{
                width: "100%",
                height: "90%",
                margin: "auto",
                borderRadius: "8px",
                position: "relative",
              }}
            >
              <FormControl fullWidth sx={{ mb: 2, color: "white" }}>
                <InputLabel sx={{ mb: 2, color: "white" }}>
                  Select Pool
                </InputLabel>
                <Select
                  value={selectedPoolId || ""}
                  label="Select Pool"
                  onChange={(e) => handlePoolSelection(e)}
                  sx={{ color: "white" }}
                >
                  <MenuItem value="">All Pools</MenuItem>
                  {pools.map((pool) => (
                    <MenuItem key={pool.id} value={pool.id}>
                      Pool {pool.id}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Box>
                <h5 style={{ color: "white" }}>
                  Stakers: {filteredStakers?.length}
                </h5>
                <h5 style={{ color: "white" }}>
                  ANTLERS:{" "}
                  {filteredStakers
                    ?.reduce(
                      (acc, staker) => acc + parseFloat(staker.staked_amount),
                      0
                    )
                    .toFixed(4)}
                </h5>
              </Box>
              <TableContainer
                component={Paper}
                variant="outlined"
                sx={{
                  mt: 2,
                  color: "white",
                  maxHeight: "60%",
                }}
              >
                <Table>
                  <TableHead
                    sx={{
                      backgroundColor: "#6f835c",
                    }}
                  >
                    <TableRow>
                      <TableCell variant="head">Rank</TableCell>
                      <TableCell variant="head">Username</TableCell>
                      <TableCell>Staked Amount</TableCell>
                      <TableCell>Lock Duration</TableCell>
                      <TableCell>Daily Rewards</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody
                    sx={{
                      backgroundColor: "#456723",
                    }}
                  >
                    {filteredStakers
                      ?.sort(
                        (a, b) =>
                          parseFloat(b.staked_amount) -
                          parseFloat(a.staked_amount)
                      )
                      ?.map((staker, index) => {
                        const pool = pools.find(
                          (pool) => pool.id === staker.pool_id
                        );
                        const apr = parseFloat(pool?.base_apr || "0"); // Extract APR
                        const dailyReward =
                          (apr / 365) * parseFloat(staker.staked_amount); // Calculate daily reward
                        const stake_delay = pool?.stake_delay || 0;
                        return (
                          <TableRow key={staker.id}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{staker.username}</TableCell>
                            <TableCell>{staker.staked_amount}</TableCell>
                            <TableCell>
                              {stake_delay / 86400 + " days"}
                            </TableCell>
                            <TableCell>
                              {dailyReward?.toFixed(4) + " ANTLERS"}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </Box>
      </Box>

      <Dialog open={showStakeDialog} onClose={() => setShowStakeDialog(false)}>
        <DialogTitle>Stake Tokens</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Amount"
            type="number"
            fullWidth
            value={stakeAmount}
            onChange={(e) => setStakeAmount(e.target.value)}
            disabled={isStaking}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setShowStakeDialog(false)}
            disabled={isStaking}
          >
            Cancel
          </Button>
          <Button onClick={handleConfirmStake} disabled={isStaking}>
            {isStaking ? <CircularProgress size={24} /> : "Stake"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
