import React from "react";
import { Box, Button, Typography, Grid, Tooltip } from "@mui/material";
import PaidIcon from "@mui/icons-material/Paid";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import DiamondIcon from "@mui/icons-material/Diamond";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import RewardIcon from "@mui/icons-material/MonetizationOn";
import CasinoIcon from "@mui/icons-material/Casino"; // For NFT drop chance
import { GetPeriodTimeString } from "../../utiles";

interface DetailedWorldCardProps {
  index: number;
  isOpen: boolean;
  cost: string | number;
  rewardInfo: any;
  onCardClick: (index: number) => void;
  isMobile: boolean;
  isDetailedView?: boolean;
}

export const DetailedWorldCard: React.FC<DetailedWorldCardProps> = ({
  index,
  isOpen,
  cost,
  rewardInfo,
  onCardClick,
  isMobile,
  isDetailedView = false,
}) => {
  const worldImageUrl = `/images/w${index}.png`;

  return (
    <Box
      sx={{
        position: "relative",
        width: isMobile ? "90vw" : isDetailedView ? "320px" : "300px",
        height: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        border: isOpen ? "2px solid #66ff66" : "2px solid #ccc",
        borderRadius: "16px",
        padding: "16px",
        backgroundColor: isOpen
          ? "rgba(26, 35, 26, 0.8)"
          : "rgba(34, 34, 34, 0.8)",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.6)",
        cursor: "pointer",
        transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
        "&:hover": {
          transform: "scale(1.05)",
          boxShadow: "0px 6px 12px rgba(102, 255, 102, 0.6)",
        },
        opacity: 0.95,
      }}
      onClick={() => onCardClick(index)}
    >
      <Typography
        variant="h5"
        sx={{
          color: isOpen ? "aquamarine" : "white",
          fontWeight: "bold",
          mb: 1,
        }}
      >
        World {index}
      </Typography>
      <img
        src={worldImageUrl}
        alt={`World ${index}`}
        style={{
          width: "100%",
          height: "auto",
          maxWidth: "280px",
          borderRadius: "12px",
          marginBottom: "12px",
          filter: "brightness(1.1)",
        }}
      />

      <Grid
        container
        spacing={2}
        justifyContent="center"
        sx={{ width: "100%", mb: 2 }}
      >
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              mb: 1,
            }}
          >
            <PaidIcon sx={{ mr: 0.5, color: "gold" }} />
            <Typography variant="body1">
              {parseFloat(rewardInfo.min_reward).toFixed(4)} -{" "}
              {parseFloat(rewardInfo.max_reward).toFixed(4)}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              mb: 1,
            }}
          >
            <TimelapseIcon sx={{ mr: 0.5, color: "lightblue" }} />
            <Typography variant="body1">
              {rewardInfo.delay / 3600} Hours
            </Typography>
          </Box>
        </Grid>

        {/* NFT Drop Chance - Always display this */}
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              mb: 1,
            }}
          >
            <Tooltip title="NFT Drop Chance">
              <CasinoIcon
                sx={{
                  mr: 0.5,
                  color: rewardInfo.nft_chance > 0 ? "violet" : "gray",
                }}
              />
            </Tooltip>
            <Typography variant="body1">
              {rewardInfo.nft_chance
                ? `${rewardInfo.nft_chance}% Chance`
                : "No NFT Drops"}
            </Typography>
          </Box>
        </Grid>

        {rewardInfo.nft_chance > 0 && (
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
                mb: 1,
              }}
            >
              <Tooltip title="NFT Drop Rarity">
                <DiamondIcon sx={{ mr: 0.5, color: "violet" }} />
              </Tooltip>
              <Typography variant="body1">
                {rewardInfo.nft_drop_rarity}
              </Typography>
            </Box>
          </Grid>
        )}

        {rewardInfo.fees && (
          <>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "white",
                }}
              >
                <Tooltip title="Base Exploration Fees">
                  <AttachMoneyIcon sx={{ mr: 0.5, color: "lightgreen" }} />
                </Tooltip>
                <Typography variant="body2" align="center">
                  Fees (GRASS):{" "}
                  {Object.keys(rewardInfo.cost_multi)?.map((key) => (
                    <span key={key}>
                      {key}:{" "}
                      {(
                        rewardInfo.cost_multi[key] * rewardInfo.baseFee
                      ).toFixed(4)}
                      {"  "}
                    </span>
                  ))}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "white",
                }}
              >
                <Tooltip title="Rewards">
                  <RewardIcon sx={{ mr: 0.5, color: "lightgreen" }} />
                </Tooltip>
                <Typography variant="body2" align="center">
                  Rewards:{" (ANTLERS) "}
                  {Object.keys(rewardInfo.reward_multi)?.map((key) => (
                    <span key={key}>
                      {key}:{" "}
                      {(
                        rewardInfo.reward_multi[key] * rewardInfo.minReward
                      ).toFixed(4)}{" "}
                      {" - "}
                      {(
                        rewardInfo.reward_multi[key] * rewardInfo.maxReward
                      ).toFixed(4)}{" "}
                    </span>
                  ))}
                </Typography>
              </Box>
            </Grid>
          </>
        )}
      </Grid>

      {!isOpen && !isDetailedView && (
        <Tooltip title="View World Details" placement="bottom" arrow>
          <Button
            variant="contained"
            color="primary"
            onClick={(e: any) => {
              e.stopPropagation();
              onCardClick(index);
            }}
            sx={{ mt: "auto", width: "100%" }}
          >
            View Details
          </Button>
        </Tooltip>
      )}
      {isOpen && !isDetailedView && (
        <Typography
          variant="body1"
          sx={{ color: "white", textAlign: "center", mt: "auto" }}
        >
          Click to Explore!
        </Typography>
      )}
    </Box>
  );
};
