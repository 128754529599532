// loader.ts

import { JsonRpc } from "eosjs";
import * as constants from "../../constant";
import * as actionTypes from "./action-types";
import { queryGameTable } from "./actions"; // Import utility function from actions.ts

const {
  assetsEndpoint,
  COLLECTION,
  GAME_CONTRACT,
  TOKEN_CONTRACT,
  SYSTEM_TOKEN_CONTRACT,
  FEED_TOKEN_CONTRACT,
} = constants;

const {
  LOGIN_SUCCESS,
  SHOW_ALERT,
  HIDE_LOADING,
  SHOW_LOADING,
  GET_EXPLORATIONS,
} = actionTypes;

let rpcInstance: JsonRpc | null = null; // Module-level variable to hold rpc instance

// Initialize or update rpcInstance
export const setRpcInstance = (rpc: JsonRpc) => {
  rpcInstance = rpc;
};

// Utility function for token balance (using module-level rpcInstance)
export const getTokenBalance = async (contract: string, account: string) => {
  if (!rpcInstance) {
    console.error("RPC instance not initialized.");
    return null;
  }
  try {
    const result = await queryGameTable({
      rpcOverride: rpcInstance, //
      contract,
      table: "accounts",
      scope: account,
      limit: 100,
    });
    return result.rows;
  } catch (error) {
    console.error(`Error fetching ${contract} balance:`, error);
    return null;
  }
};

export const getAllIngameTokens = async (account: string) => {
  if (!rpcInstance) {
    console.error("RPC instance not initialized.");
    return null;
  }
  try {
    const result = await queryGameTable({
      rpcOverride: rpcInstance, //
      contract: GAME_CONTRACT,
      table: "accbalance",
      scope: account,
      limit: 1000,
    });
    return result.rows;
  } catch (error) {
    console.error(`Error fetching  balance:`, error);
    return null;
  }
};

// Separate data fetching functions - Optimized Promise.all and conciseness (using module-level rpcInstance)
export const fetchAssets = async (account: string) => {
  let assets: any[] = [];
  for (let page = 1; ; page++) {
    const response = await fetch(
      `${assetsEndpoint}atomicassets/v1/assets?owner=${account}&collection_name=${COLLECTION}&page=${page}&limit=1000&sort=asset_id`
    );
    const result = (await response.json()).data;
    if (result.length === 0) break;
    assets.push(...result);
  }
  return assets.sort((a: any, b: any) => a.asset_id - b.asset_id);
};

export const fetchTokenBalances = async (account: string) => {
  if (!rpcInstance) {
    console.error("RPC instance not initialized.");
    return {
      wax_balance: "0 WAX",
      grass_balance: "0 GRASS",
      antler_balance: "0 ANTLER",
      gold_balance: "0 GOLD",
    };
  }
  const [systemToken, feedToken, antlerToken, goldToken] = await Promise.all([
    getTokenBalance(SYSTEM_TOKEN_CONTRACT, account),
    getTokenBalance(FEED_TOKEN_CONTRACT, account),
    getTokenBalance(TOKEN_CONTRACT, account),
    getTokenBalance(FEED_TOKEN_CONTRACT, account),
  ]);

  const allIngameTokens = await getAllIngameTokens(account);
  const game_grass =
    allIngameTokens?.find((t: any) => t.balance?.includes("GRASS"))?.balance ||
    "0 GRASS";
  const game_antlers =
    allIngameTokens?.find((t: any) => t.balance?.includes("ANTLERS"))
      ?.balance || "0 ANTLERS";
  const game_gold =
    allIngameTokens?.find((t: any) => t.balance?.includes("GOLD"))?.balance ||
    "0 GOLD";
  const game_xp =
    allIngameTokens?.find((t: any) => t.balance?.includes("XP"))?.balance ||
    "0 XP";

  return {
    wax_balance: systemToken?.[0]?.balance || "0 WAX",
    grass_balance:
      feedToken?.find((t: any) => t.balance.includes("GRASS"))?.balance ||
      "0 GRASS",
    antler_balance:
      antlerToken?.find((t: any) => t.balance.includes("ANTLER"))?.balance ||
      "0 ANTLERS",
    gold_balance:
      goldToken?.find((t: any) => t.balance.includes("GOLD"))?.balance ||
      "0 GOLD",
    game_antlers,
    game_gold,
    game_grass,
    game_xp,
  };
};

export const fetchWorldData = async (account: string) => {
  if (!rpcInstance) {
    console.error("RPC instance not initialized.");
    return { open_worlds: [], world_cost: [] };
  }
  const userWorlds = await queryGameTable({
    rpcOverride: rpcInstance, //
    table: "userworld",
    lower_bound: account,
    upper_bound: account,
    limit: 1,
  });
  return { open_worlds: userWorlds.rows[0]?.openworlds || [], world_cost: [] };
};

export const fetchGameConfigs = async () => {
  if (!rpcInstance) {
    console.error("RPC instance not initialized.");
    return { reward_info: [], tool_configs: [], deer_configs: [] };
  }
  const [rewardInfo, toolConfigs, deerConfigs] = await Promise.all([
    queryGameTable({
      rpcOverride: rpcInstance,
      table: "worldinfo",
      limit: 1000,
    }), //
    queryGameTable({
      rpcOverride: rpcInstance,
      table: "toolinfos",
      limit: 1000,
    }), //
    queryGameTable({
      rpcOverride: rpcInstance,
      table: "deerconfigs",
      limit: 1000,
    }), //, Corrected table name to deerinfos
  ]);
  return {
    reward_info: rewardInfo.rows || [],
    tool_configs: toolConfigs.rows || [],
    deer_configs: deerConfigs.rows || [],
  };
};

export const fetchIndexedTable = async (account: string, table: string) => {
  if (!rpcInstance) {
    console.error("RPC instance not initialized.");
    return [];
  }
  const result = await queryGameTable({
    rpcOverride: rpcInstance, //
    table: table,
    lower_bound: account,
    limit: 1000,
    key_type: "i64",
    index_position: 2,
  });
  return result.rows;
};

export const fetchStakingData = async () => {
  if (!rpcInstance) {
    console.error("RPC instance not initialized.");
    return { pool_info: [], staking_pool: [] };
  }
  const [poolInfo, stakersInfo] = await Promise.all([
    queryGameTable({
      rpcOverride: rpcInstance,
      table: "stakingpools",
      limit: 1000,
    }), //
    queryGameTable({ rpcOverride: rpcInstance, table: "stakers", limit: 1000 }), //
  ]);
  return { pool_info: poolInfo.rows, staking_pool: stakersInfo.rows || [] };
};
export const GetExplorations = () => async (dispatch: any, account: string) => {
  try {
    const result = await queryGameTable({
      table: "explores",
      limit: 1000,
      index_position: 2,
      key_type: "i64",
      lower_bound: account,
      upper_bound: account,
    });

    const explorations = result.rows;

    dispatch({ type: GET_EXPLORATIONS, payload: { explorations } });
  } catch (e: any) {
    console.error("Get explorations error:", e);
  }
};
// Enhanced asset exploration data function
export const getAssetExplorationData = (
  asset: any,
  exploredNfts: any[],
  toolConfigs: any[],
  deerConfigs: any[]
) => {
  const explorationData = exploredNfts.find(
    (nft) => nft.asset_id === asset.asset_id
  ) || { level: "1" };
  explorationData.level = asset.mutable_data?.Level || explorationData.level;

  return {
    stakedConfig: exploredNfts.find((nft) => nft.asset_id === asset.asset_id),
    exploration_time: explorationData.last_claimed || explorationData.last_used,
    upgrade_end_at: explorationData.upgrade_end_at,
    current_explore: explorationData.current_explore,
    level: explorationData.level,
    tool_config: toolConfigs.find(
      (config) =>
        parseInt(config.template_id) === parseInt(asset.template?.template_id)
    ),
    deer_config: deerConfigs.find(
      (config) =>
        parseInt(config.template_id) === parseInt(asset.template?.template_id)
    ),
  };
};

// Centralized Load Data Function
export const loadDataAction = async (
  dispatch: any,
  account: string,
  session: any,
  rpc: JsonRpc
) => {
  setRpcInstance(rpc); // Set rpcInstance before using data fetching functions
  dispatch({ type: SHOW_LOADING });
  try {
    const [
      gameConfigs,
      assets,
      tokenBalances,
      worldData,
      stakedTools,
      stakedDeers,
      stakingData,
    ] = await Promise.all([
      fetchGameConfigs(),
      fetchAssets(account),
      fetchTokenBalances(account),
      fetchWorldData(account),
      fetchIndexedTable(account, "tools"),
      fetchIndexedTable(account, "deers"),
      fetchStakingData(),
    ]);
    await GetExplorations()(dispatch, account); // Fetch explorations
    const enhancedAssets = assets.map((item) => ({
      ...item,
      ...getAssetExplorationData(
        item,
        stakedTools.concat(stakedDeers),
        gameConfigs.tool_configs,
        gameConfigs.deer_configs
      ),
    }));

    dispatch({
      type: LOGIN_SUCCESS,
      payload: {
        session,
        balance: { ...tokenBalances },
        assets: enhancedAssets,
        tool_configs: gameConfigs.tool_configs,
        deer_configs: gameConfigs.deer_configs,
        reward_info: gameConfigs.reward_info,
        ...worldData,
        ...stakingData,
      },
    });
  } catch (error: any) {
    console.error("Load data error:", error);
    dispatch({
      type: SHOW_ALERT,
      payload: { type: "error", msg: error.toString(), show: true },
    });
  } finally {
    dispatch({ type: HIDE_LOADING });
  }
};
