import {
  Box,
  Grid,
  Button,
  Typography,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  Modal,
  Fade,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// Array containing the instructions
const instructions = [
  {
    title: "What is Staking?",
    content:
      "Staking allows you to lock up your tokens in a pool to earn rewards. Rewards are calculated based on the pool's APR and the amount of tokens staked.",
  },
  {
    title: "How to Stake?",
    content:
      "Choose a pool, enter the amount you wish to stake, and confirm the transaction. Ensure you have enough tokens in your wallet before staking. You can only stake in one pool at a time (temporary restriction).",
  },
  {
    title: "Rewards and APR",
    content:
      "Rewards are distributed daily based on the pool's Annual Percentage Rate (APR). The longer you stake, the more rewards you earn.",
  },
  {
    title: "Unstaking",
    content:
      "You can unstake your tokens anytime unless a lock period is active. Note that rewards stop accruing after unstaking.",
  },
  {
    title: "Leaderboards",
    content:
      "Leaderboards show the top stakers in each pool based on their staked amount and duration. Compete to secure your rank! Extra rewards such as weekly or monthly NFT airdrops will be sent to random stakers!",
  },
];

export const InstructionsModal = ({
  showInstructions,
  setShowInstructions,
}: any) => {
  return (
    <Modal
      open={showInstructions}
      onClose={() => setShowInstructions(false)}
      closeAfterTransition
    >
      <Fade in={showInstructions}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxWidth: "500px",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h5" sx={{ mb: 2 }}>
            Staking Instructions
          </Typography>
          <List>
            {instructions.map((instruction, index) => (
              <ListItem key={index} sx={{ mb: 2 }}>
                <ListItemText
                  primary={
                    <Typography variant="subtitle1">
                      {instruction.title}
                    </Typography>
                  }
                  secondary={
                    <Typography variant="body2">
                      {instruction.content}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
          <Button
            variant="contained"
            onClick={() => setShowInstructions(false)}
            sx={{ mt: 2 }}
            fullWidth
          >
            Close
          </Button>
        </Box>
      </Fade>
    </Modal>
  );
};
