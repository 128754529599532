import {
  UserState,
  UserActionTypes,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  WORLD_SELECT,
  EXPLORATION_SUCCESS,
  BUY_WORLD,
  SHOW_ALERT,
  SHOW_LOADING,
  HIDE_LOADING,
  STAKE_SUCCESS,
  CLAIM_STAKE,
  BUY_GOLD,
  GET_LEADERBOARD,
  LEVELUP_SUCCESS,
  LEVELUP_BOOST_SUCCESS,
  GET_TOURNAMENT_LIST,
  STAKE_TOOL_SUCCESS,
  GET_EXPLORATIONS,
  CLAIM_EXPLORATION_SUCCESS,
  ENTER_TOURNAMENT_SUCCESS,
} from "./action-types";

const initialState: UserState = {
  loginInfo: {
    session: null,
    account: "",
    balance: {
      wax_balance: "0 WAX",
      gold_balance: "0 GOLD",
      grass_balance: "0 GRASS",
      antler_balance: "0 ANTLER",
    },
    assets: null,
    isLogin: false,
    open_worlds: [1],
    staking_pool: [],
    tool_configs: [],
    deer_configs: [],
  },

  gameStatus: {
    world_cost: [],
    reward_info: [],
    pool_info: [],
    leaderboard: [],
    tournaments: [],
    myScore: null,
    world: 0,

    alert: {
      type: "success",
      message: "",
      show: false,
    },

    loading: false,
  },
};

export function userReducer(state = initialState, action: any): UserState {
  switch (action.type) {
    case LOGIN_REQUEST: {
      const { loginInfo, gameStatus } = { ...state };
      loginInfo.isLogin = false;

      return { loginInfo, gameStatus };
    }
    case LOGIN_SUCCESS: {
      const {
        session,
        balance,
        assets,
        world_cost,
        open_worlds,
        reward_info,
        pool_info,
        staking_pool,
        tool_configs,
        deer_configs,
      } = action.payload;

      const { loginInfo, gameStatus } = { ...state };

      loginInfo.isLogin = session ? true : false;
      loginInfo.session = session;
      loginInfo.account = session
        ? session.permissionLevel.actor.toString()
        : "";
      loginInfo.assets = assets;
      loginInfo.balance = balance;
      loginInfo.open_worlds = open_worlds;
      loginInfo.open_worlds.push(1);
      loginInfo.staking_pool = staking_pool;
      loginInfo.tool_configs = tool_configs;
      loginInfo.deer_configs = deer_configs;

      gameStatus.world_cost = world_cost;
      gameStatus.reward_info = reward_info;

      gameStatus.pool_info = pool_info?.map((item: any) => {
        let _item = { ...item };

        let staked_pool = staking_pool?.find(
          (pool: any) => pool.pool_id == _item.id
        );
        if (staked_pool) {
          _item = { ..._item, ...staked_pool };
          _item["is_staked"] = true;
        }

        return _item;
      });
      return { loginInfo, gameStatus };
    }

    case LOGIN_ERROR: {
      const { error } = action.payload;
      const { loginInfo, gameStatus } = { ...state };
      loginInfo.isLogin = false;

      return { loginInfo, gameStatus };
    }

    case BUY_WORLD: {
      const { world } = action.payload;

      const { loginInfo, gameStatus } = { ...state };

      loginInfo.open_worlds.push(world);

      return { loginInfo, gameStatus };
    }

    case WORLD_SELECT: {
      const { world } = action.payload;

      const { loginInfo, gameStatus } = { ...state };

      gameStatus.world = world;

      return { loginInfo, gameStatus };
    }

    case EXPLORATION_SUCCESS: {
      const { asset_ids, type } = action.payload;

      const { loginInfo, gameStatus } = { ...state };

      return { loginInfo, gameStatus };
    }

    case STAKE_TOOL_SUCCESS: {
      const { ids, type } = action.payload;
      const { loginInfo, gameStatus } = { ...state };

      loginInfo.assets = loginInfo.assets?.map((item: any) => {
        const new_item = { ...item };

        const isPresent = ids?.includes(item.asset_id);
        if (isPresent) {
          const currentUnixTime = Math.floor(new Date().getTime() / 1000);
          new_item.exploration_time = currentUnixTime + 86400;
        }

        return new_item;
      });

      return { loginInfo, gameStatus };
    }

    case STAKE_SUCCESS: {
      const { pool_id, amount } = action.payload;
      const { loginInfo, gameStatus } = { ...state };

      gameStatus.pool_info = gameStatus.pool_info?.map((pool: any) => {
        if (pool.id == pool_id) {
          const currentUnixTime = Math.floor(new Date().getTime() / 1000);

          pool.is_staked = true;
          pool.cooldown_until = currentUnixTime + pool.stake_delay;
          pool.balance = amount;
        }
        return pool;
      });

      return { loginInfo, gameStatus };
    }

    case CLAIM_STAKE: {
      const { pool_id } = action.payload;
      const { loginInfo, gameStatus } = { ...state };

      gameStatus.pool_info = gameStatus.pool_info?.map((pool: any) => {
        if (pool.id == pool_id) pool.is_staked = false;
        return pool;
      });

      return { loginInfo, gameStatus };
    }

    case BUY_GOLD: {
      const { amount, receive_amount } = action.payload;
      const { loginInfo, gameStatus } = { ...state };

      loginInfo.balance.wax_balance =
        parseFloat(loginInfo.balance.wax_balance) - amount + "WAX";
      loginInfo.balance.wax_balance =
        parseFloat(loginInfo.balance.gold_balance) + receive_amount + "GOLD";

      return { loginInfo, gameStatus };
    }

    case GET_LEADERBOARD: {
      const { leaderboard, myScore } = action.payload;
      const { loginInfo, gameStatus } = { ...state };

      gameStatus.leaderboard = leaderboard;
      gameStatus.myScore = myScore;

      return { loginInfo, gameStatus };
    }

    case LEVELUP_SUCCESS: {
      const { asset_id, current_level } = action.payload;
      const { loginInfo, gameStatus } = { ...state };

      let delay = current_level * current_level * 30 * 10;

      loginInfo.assets = loginInfo.assets.map((item: any) => {
        if (item.asset_id == asset_id) {
          item.level = current_level + 1;
          const currentUnixTime = Math.floor(new Date().getTime() / 1000);
          item.upgrade_end_at = currentUnixTime + delay;
        }
        return item;
      });

      return { loginInfo, gameStatus };
    }

    case LEVELUP_BOOST_SUCCESS: {
      const { asset_id, boostAmount } = action.payload;
      const { loginInfo, gameStatus } = { ...state };

      loginInfo.assets = loginInfo.assets.map((item: any) => {
        if (item.asset_id == asset_id) {
          item.upgrade_end_at = item.upgrade_end_at - boostAmount * 60;
        }
        return item;
      });

      return { loginInfo, gameStatus };
    }

    case ENTER_TOURNAMENT_SUCCESS: {
      const { id } = action.payload;
      const { loginInfo, gameStatus } = { ...state };

      gameStatus.tournaments = gameStatus.tournaments.map((item: any) => {
        if (item.id == id) {
          item.is_entered = true;
        }

        return item;
      });

      return { loginInfo, gameStatus };
    }

    case GET_TOURNAMENT_LIST: {
      const { tournaments } = action.payload;
      const { loginInfo, gameStatus } = { ...state };

      let _tournaments = tournaments.map((item: any) => {
        let is_entered = false;

        if (item.user.find((user: any) => user.user == loginInfo.account)) {
          is_entered = true;
        }
        console.log("---");
        console.log(item.user, is_entered);
        console.log("---");
        item.is_entered = is_entered;
        if (item.hist.length > 0) {
          let final = item.hist[item.hist.length - 1];
          item.winner1 = final.winner;
          item.winner2 =
            final.winner.asset_id == final.player1.asset_id
              ? final.player2
              : final.player1;
        } else {
          item.winner1 = { user: "", asset_id: "" };
          item.winner2 = { user: "", asset_id: "" };
        }

        return item;
      });

      console.log(_tournaments);

      gameStatus.tournaments = _tournaments;

      return { loginInfo, gameStatus };
    }

    case SHOW_ALERT: {
      const { type, msg, show } = action.payload;

      const { loginInfo, gameStatus } = { ...state };

      gameStatus.alert = {
        show: show,
        type: type,
        message: msg,
      };

      return { loginInfo, gameStatus };
    }

    case SHOW_LOADING: {
      const { loginInfo, gameStatus } = { ...state };
      gameStatus.loading = true;
      return { loginInfo, gameStatus };
    }

    case HIDE_LOADING: {
      const { loginInfo, gameStatus } = { ...state };
      gameStatus.loading = false;
      return { loginInfo, gameStatus };
    }

    case GET_EXPLORATIONS: {
      const { explorations } = action.payload;
      const { loginInfo, gameStatus } = { ...state };

      gameStatus.explorations = explorations;

      return { loginInfo, gameStatus };
    }

    case CLAIM_EXPLORATION_SUCCESS: {
      const { exploreId } = action.payload;
      const { loginInfo, gameStatus } = { ...state };

      // Remove the claimed exploration from the list
      gameStatus.explorations = gameStatus.explorations.filter(
        (exp: any) => exp.id !== exploreId
      );

      return { loginInfo, gameStatus };
    }

    default:
      return { ...state };
  }
}
