import {
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  CircularProgress,
} from "@mui/material";
import Countdown from "react-countdown";

interface Pool {
  id: number;
  min_stake: string;
  total_staked: string;
  stake_delay: number;
  base_apr: number;
  monthly_increase: number;
  is_open: boolean;
  reward_type: string;
}

interface Staker {
  id: number;
  pool_id: number;
  username: string;
  staked_amount: string;
  pending_rewards: string;
  end_time: number;
  start_time: number;
  last_reward_update: number;
}

interface StakingPoolCardProps {
  pool: Pool;
  stakerInfo: Staker | null;
  onStake: () => void;
  onClaim: () => void;
  onUnstake: () => void;
}

export const StakingPoolCard = ({
  pool,
  stakerInfo,
  onStake,
  onClaim,
  onUnstake,
}: StakingPoolCardProps) => {
  const isStaked = stakerInfo?.pool_id === pool.id;
  const timeUntilUnlock = isStaked ? 0 : null;

  return (
    <Card
      sx={{
        backgroundColor: "#ffffff15",
        color: "white",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        borderRadius: "5%",
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          backgroundColor: `${pool.id == 1 ? "#0c320d" : "#1a1f3c"}`,
        }}
      >
        <Typography variant="h5" component="div" gutterBottom>
          Pool {pool.id} - {pool.reward_type}
        </Typography>

        <Typography variant="body2" gutterBottom>
          APR: {(pool.base_apr * 100).toFixed(2)}%
        </Typography>

        <Typography variant="body2" gutterBottom>
          Monthly Increase: {(pool.monthly_increase * 100).toFixed(2)}%
        </Typography>

        <Typography variant="body2" gutterBottom>
          Lock Period: {pool.stake_delay / 86400} days
        </Typography>

        <Typography variant="body2" gutterBottom>
          Minimum Stake: {pool.min_stake}
        </Typography>

        {isStaked && (
          <>
            <Typography variant="body2" gutterBottom>
              Your Stake: {stakerInfo.staked_amount}
            </Typography>

            <Typography variant="body2" gutterBottom>
              Pending Rewards: {stakerInfo.pending_rewards}
            </Typography>
          </>
        )}

        <Box sx={{ mt: 2, display: "flex", gap: 1, justifyContent: "center" }}>
          {!isStaked ? (
            <Button
              variant="contained"
              onClick={onStake}
              disabled={!pool.is_open}
            >
              Stake
            </Button>
          ) : (
            <div className="row">
              <div className="flex-col">
                <h4>
                  *Adding more tokens to your stake will reset your lock period*
                </h4>
                <Button
                  variant="contained"
                  onClick={onStake}
                  disabled={!pool.is_open}
                >
                  Stake
                </Button>{" "}
              </div>
              <div className="flex-col">
                <h6>CLAIM</h6>
                <Button
                  variant="contained"
                  onClick={onClaim}
                  disabled={
                    Date.now() / 1000 < stakerInfo.last_reward_update + 86400
                  }
                  style={{
                    color: "white",
                  }}
                >
                  <Countdown
                    date={(stakerInfo.last_reward_update + 86400) * 1000}
                  >
                    <p>CLAIM</p>
                  </Countdown>
                </Button>
              </div>
              <div className="flex-col">
                <h6>UNSTAKE</h6>{" "}
                <Button
                  variant="contained"
                  onClick={onUnstake}
                  disabled={Date.now() / 1000 < stakerInfo.end_time}
                  style={{
                    color: "grey",
                  }}
                >
                  <Countdown date={stakerInfo.end_time * 1000}>
                    <p>Unstake</p>
                  </Countdown>
                </Button>
              </div>
            </div>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};
