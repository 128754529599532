export const MODE: string = "main"; //"dev" // main

export const COLLECTION = MODE == "dev" ? "captainhoney" : "waxdeerjc";
export const GAME_CONTRACT = MODE == "dev" ? "ninjatestwax" : "gam.waxdeers";
export const TOKEN_CONTRACT = MODE == "dev" ? "tokenwaxdeer" : "tok.waxdeers";
export const TOKEN_SYMBOL = "ANTLERS";

export const SYSTEM_TOKEN_CONTRACT = "eosio.token";
export const SYSTEM_TOKEN_SYMBOL = "WAX";

export const GOLD_TOKEN_CONTRACT = MODE == "dev" ? "tokenwaxdeer" : "waxdeerjc";
export const GOLD_TOKEN_SYMBOL = "GOLD";

export const FEED_TOKEN_CONTRACT = MODE == "dev" ? "tokenwaxdeer" : "waxdeerjc";
export const FEED_TOKEN_SYMBOL = "GRASS";

export const NFT_TYPE = MODE == "dev" ? "buildings" : "fawn";

export const chainInfo = {
  mainnet: {
    id: "1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4",
    url: "https://api.wax.alohaeos.com",
  },
  testnet: {
    id: "f16b1833c747c43682f4386fca9cbb327929334a762755ebec17f6f23c9b8a12", //"1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4",
    url: "https://waxtestnet.greymass.com", //"https://wax.greymass.com",
  },
};

export const chainEndpoint =
  MODE == "dev" ? chainInfo.testnet : chainInfo.mainnet;
export const hyperionEndpoint =
  MODE == "dev"
    ? "https://testnet.waxsweden.org/v2/history/get_actions"
    : "https://wax.eosrio.io/v2/history/get_actions";
export const assetsEndpoint =
  MODE == "dev"
    ? "http://aa-testnet-public.neftyblocks.com/"
    : "https://wax.api.atomicassets.io/";
