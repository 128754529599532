import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Modal,
  Backdrop,
  Fade,
  ModalProps,
} from "@mui/material"; // Import ModalProps
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../store";
import { BuyWorldAction } from "../../store/user/actions";
import { GetPeriodTimeString } from "../../utiles";
import { DetailedWorldCard } from "./DetailedWorldCard";
import { ToastContainer, toast } from "react-toastify";

interface WorldModalProps {
  open: boolean;
  onClose: () => void;
}

export const WorldModal: React.FC<WorldModalProps> = ({ open, onClose }) => {
  const dispatch = useDispatch<any>();
  const { loginInfo, gameStatus } = useSelector(
    (state: AppState) => state.userModule
  );
  const [selectedWorldIndex, setSelectedWorldIndex] = useState<number | null>(
    null
  ); // Track selected world for details/buy
  const [worldCost, setWorldCost] = useState("");
  const [rewardInfo, setRewardInfo] = useState<any>({
    stake_delay: 0,
    reward_min_range: 0,
    reward_max_range: 0,
    nft_drop_chance: 0,
    min_deer_level: 0,
    fees: { og: 0, abyssal: 0, promo: 0 }, // Initialize fees
    nft_drop_rarity: "",
    exploration_durations: [],
  });

  useEffect(() => {
    if (open && selectedWorldIndex !== null) {
      // When modal opens and a world is selected (after card click)
      let cost = gameStatus.world_cost.find(
        (item: any) => item.id == selectedWorldIndex
      );
      let reward = gameStatus.reward_info.find(
        (item: any) => item.id == selectedWorldIndex
      );
      setWorldCost(
        parseFloat(reward.unlock_fee?.split(" ")?.[0])?.toFixed(4) +
          " ANTLERS" || ""
      );
      setRewardInfo(
        reward || {
          fees: { og: 0, abyssal: 0, promo: 0 }, // Default fees if not found
          nft_drop_rarity: "",
          exploration_durations: [],
        }
      );
    } else if (open && selectedWorldIndex === null) {
      // When modal is initially opened, no world selected yet
      setWorldCost("");
      setRewardInfo({
        stake_delay: 0,
        reward_min_range: 0,
        reward_max_range: 0,
        nft_drop_chance: 0,
        min_deer_level: 0,
        fees: { og: 0, abyssal: 0, promo: 0 }, // Default fees
        nft_drop_rarity: "",
        exploration_durations: [],
      });
    }
  }, [open, gameStatus, selectedWorldIndex]);

  const handleCardClick = (index: number) => {
    if (selectedWorldIndex === index) {
      // If already selected, treat as confirmation to buy (or explore if already bought)
      if (!loginInfo.open_worlds.includes(index)) {
        buyWorld(); // Buy only if not already opened
      } else {
        onClose(); // Just close modal if world already opened (and maybe navigate in CombinedHomePage later if needed)
        toast.info(
          `World ${index} already purchased. Select it from world header to explore.`
        );
      }
    } else {
      // First click: select world and show details
      setSelectedWorldIndex(index);
    }
  };

  const buyWorld = () => {
    if (selectedWorldIndex === null) return;
    dispatch(
      BuyWorldAction(selectedWorldIndex, (result: any) => {
        if (result) {
          onClose();
          setSelectedWorldIndex(null); // Reset selection after purchase
          toast.success(`World ${selectedWorldIndex} Purchased Successfully!`);
        }
      })
    );
  };

  const modalStyle = {
    position: "absolute" as "absolute", // Correct type assertion
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: 800,
    bgcolor: "transparent", // Modal background is transparent, world image will be backdrop
    boxShadow: 24,
    p: 0, // No padding for modal content itself, padding in card
    color: "white",
    borderRadius: "16px",
    maxHeight: "95vh", // Slightly taller modal
    overflowY: "auto",
  };

  const backdropStyle = {
    backdropFilter: "blur(10px)", // Blur effect for background
    backgroundColor: "rgba(0,0,0,0.8)", // Darken background slightly
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="world-modal-title"
      aria-describedby="world-modal-description"
      BackdropComponent={Backdrop}
      BackdropProps={{
        style: backdropStyle,
      }}
    >
      <Box sx={modalStyle}>
        {selectedWorldIndex !== null && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              zIndex: -1, // Behind the modal content
              overflow: "hidden",
              filter: "blur(8px) brightness(0.5)", // Deeper blur and dimming
            }}
          >
            <img
              src={`/images/w${selectedWorldIndex}.png`}
              alt={`World ${selectedWorldIndex} Background`}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover", // Cover the entire area
                opacity: 0.7, // Adjust opacity of background image if needed
              }}
            />
          </Box>
        )}

        <Typography
          id="world-modal-title"
          variant="h4"
          component="h2"
          textAlign="center"
          color="aquamarine"
          mt={3}
          mb={2}
        >
          {selectedWorldIndex
            ? `World ${selectedWorldIndex} Details`
            : "Select a World to Explore"}
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
            gap: 3,
            padding: 2, // Add padding to the content area
          }}
        >
          {gameStatus.reward_info.map((item: any, index: number) => {
            // Check if the world is open or not
            const isOpen = loginInfo.open_worlds.includes(index);
            // Set the world cost based on whether it's open or not
            return (
              <DetailedWorldCard
                key={index}
                index={index + 1}
                isOpen={loginInfo.open_worlds.includes(index)}
                cost={parseFloat(item.unlock_fee?.split(" ")?.[0])}
                rewardInfo={{
                  fees: {
                    og: (
                      parseFloat(item.base_fee?.split(" ")?.[0]) * 5
                    ).toFixed(4), // Example fees
                    abyssal: parseFloat(item.base_fee?.split(" ")?.[0]),
                    fawn: parseFloat(item.base_fee?.split(" ")?.[0]),
                    promo: parseFloat(item.base_fee?.split(" ")?.[0]),
                  }, // Example fees if not in rewardInfo
                  cost_multi: {
                    og: 1,
                    abyssal: 1,
                    fawn: 5,
                    promo: 0.1,
                  },
                  reward_multi: {
                    og: 1,
                    abyssal: 1,
                    fawn: 5,
                    promo: 0.1,
                  },
                  nft_drop_rarity: item.nft_chance == 0 ? "None" : "Enabled",
                  exploration_durations: [item.delay / 3600], // Example durations
                  minReward: parseFloat(item.min_reward).toFixed(4),
                  maxReward: parseFloat(item.max_reward).toFixed(4),
                  baseFee: parseFloat(item.base_fee).toFixed(4),
                  ...item,
                }}
                onCardClick={handleCardClick} // Use handleCardClick for card clicks
                isMobile={false}
                isDetailedView={selectedWorldIndex === index} // Indicate detailed view if this card is selected in modal
              />
            );
          })}
        </Box>

        {selectedWorldIndex !== null &&
          !loginInfo.open_worlds.includes(selectedWorldIndex) && ( // Show buy confirm only if world is selected and NOT opened yet
            <Box sx={{ mt: 4, textAlign: "center", padding: 2 }}>
              <Typography variant="h6" color="lightblue" mb={2}>
                Confirm Purchase of World {selectedWorldIndex}?
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <strong>Cost:</strong> {worldCost}
              </Typography>

              <Box sx={{ mt: 3 }}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={buyWorld}
                  sx={{ mr: 2 }}
                >
                  Buy World
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={(e: any) => setSelectedWorldIndex(null)}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          )}
        {selectedWorldIndex !== null &&
          loginInfo.open_worlds.includes(selectedWorldIndex) && ( // Message if world is already purchased
            <Box sx={{ mt: 4, textAlign: "center", padding: 2 }}>
              <Typography variant="h6" color="lightgreen" mb={2}>
                World {selectedWorldIndex} is already Purchased!
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Select it from world header to explore.
              </Typography>
              <Box sx={{ mt: 3 }}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    onClose();
                    setSelectedWorldIndex(null);
                  }}
                >
                  Close
                </Button>
              </Box>
            </Box>
          )}

        <ToastContainer />
      </Box>
    </Modal>
  );
};
