import React, { useState, useCallback, useMemo } from "react";
import DeerCard from "../../components/DeerCard";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import {
  Box,
  Button,
  Typography,
  FormGroup,
  FormControlLabel,
  Switch,
  Divider,
  Paper,
} from "@mui/material";
import { Exploration, Reload } from "../../store/user/actions";
import { AppState } from "../../store";
import { NFT_TYPE } from "../../constant";
import Slider from "@mui/material/Slider"; // Add this import at the top

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const URI_PREFIX = "https://ipfs.neftyblocks.io/ipfs/";
const ABYSSAL_TEMP = "QmesXd95twNg9hyR8NVB2b4ZDB8roCoLfjr7dtD1SZ8NAm";
const MAX_SELECT_COUNT = 50;

// Utility function for rarity comparison
const RARITY_ORDER = {
  Common: 1,
  Uncommon: 2,
  Rare: 3,
  Epic: 4,
  Legendary: 5,
  Mythic: 6,
} as any;

export const Home = () => {
  const { loginInfo, gameStatus } = useSelector(
    (state: AppState) => state.userModule
  );
  const dispatch = useDispatch<any>();
  const [sliderValue, setSliderValue] = useState<number>(0);

  const [selectedNfts, setSelectedNfts] = useState<Set<string>>(new Set());
  const [nftType, setNftType] = useState<string>("ALL");
  const [showImage, setShowImage] = useState<boolean>(false);

  const currentWorld = gameStatus.world || "";

  // Filter and sort NFTs by type, availability, rarity, and level
  const filteredNfts = useMemo(() => {
    if (!loginInfo.assets || !Array.isArray(loginInfo.assets)) return [];

    let filtered = loginInfo.assets.filter((item: any) => item.deer_config);

    // Filter by deer type
    if (nftType !== "ALL") {
      filtered = filtered.filter((item: any) => {
        const schemaName = item.schema?.schema_name?.toLowerCase() || "";

        switch (nftType) {
          case "fawn":
            return schemaName === "fawn";
          case "abyssal":
            return schemaName === "abyssal";
          case "og":
            return schemaName === "og.deer";
          case "promo":
            return (
              schemaName === "promo" ||
              item.template?.template_id?.toString() === "859701"
            );
          default:
            return false;
        }
      });
    }

    // Sort first by exploration time, then by rarity, then by level
    return filtered.sort((a: any, b: any) => {
      // First, sort by exploration time
      if (a.exploration_time !== b.exploration_time) {
        return a.exploration_time - b.exploration_time;
      }

      // Then by rarity
      const rarityA = a.data?.Rarity ? RARITY_ORDER[a.data.Rarity] || 0 : 0;
      const rarityB = b.data?.Rarity ? RARITY_ORDER[b.data.Rarity] || 0 : 0;

      if (rarityA !== rarityB) {
        return rarityB - rarityA;
      }

      // Finally by level
      return parseInt(b.level || "1") - parseInt(a.level || "1");
    });
  }, [loginInfo.assets, nftType]);

  // Check if an NFT is explorable
  const isExplorable = useCallback((asset: any) => {
    const currentTime = Math.floor(Date.now() / 1000);
    console.log(asset);
    return (
      asset.deer_config &&
      asset.stakedConfig?.current_explore == 0 &&
      asset.upgrade_end_at == 0
    );
  }, []);

  // Handle NFT Selection/Deselection
  const handleNFTClick = useCallback((assetId: string) => {
    setSelectedNfts((prevSelected) => {
      const newSelected = new Set(prevSelected);

      if (newSelected.has(assetId)) {
        newSelected.delete(assetId);
      } else if (newSelected.size < MAX_SELECT_COUNT) {
        newSelected.add(assetId);
      } else {
        toast.error(`You can select a maximum of ${MAX_SELECT_COUNT} NFTs.`);
      }

      return newSelected;
    });
  }, []);

  // Handle Select All/Deselect All
  const handleSelectAll = useCallback(() => {
    try {
      setSelectedNfts((prevSelected) => {
        if (
          prevSelected.size === filteredNfts.length ||
          prevSelected.size === MAX_SELECT_COUNT
        ) {
          return new Set(); // Deselect all
        } else {
          // Select all explorable filtered NFTs up to the max limit
          const newSelected = new Set<string>();
          let count = 0;
          let max = Math.min(
            filteredNfts.filter((item: any) => isExplorable(item)).length,
            MAX_SELECT_COUNT
          );

          for (const nft of filteredNfts) {
            if (isExplorable(nft) && count < MAX_SELECT_COUNT) {
              newSelected.add(nft.asset_id);
              count++;
            }
          }

          return newSelected;
        }
      });
    } catch (error) {
      console.error("Error selecting/deselecting NFTs:", error);
      toast.error("An error occurred while selecting/deselecting NFTs.");
    }
  }, [filteredNfts, isExplorable]);

  // Do Exploration
  // 6. Fix in Home.tsx - Update the doExploration function
  const doExploration = useCallback(async () => {
    const selectedNftArray = Array.from(selectedNfts);

    if (selectedNftArray.length === 0) {
      toast.error("Please select at least one NFT.");
      return;
    }

    // Dispatch and await the result
    const success = await dispatch(Exploration(currentWorld, selectedNftArray));

    if (success) {
      setSelectedNfts(new Set());
      toast.success("Exploration started successfully.");
    }
  }, [dispatch, currentWorld, selectedNfts]);

  // Handler for NFT type change
  const handleNftTypeChange = useCallback(
    (event: React.MouseEvent<HTMLElement>, value: string) => {
      if (value) {
        setNftType(value);
        setSelectedNfts(new Set()); // Clear selected NFTs when changing type
      }
    },
    []
  );

  // Handler for Show Image Switch Change
  const handleSwitchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setShowImage(event.target.checked);
    },
    []
  );

  // Calculate Total Fee
  const totalFee = useMemo(() => {
    if (selectedNfts.size === 0) return "0.0000";

    const worldInfo = gameStatus?.reward_info?.find(
      (world: any) => world.id === currentWorld
    );

    if (!worldInfo || !worldInfo.base_fee) return "0.0000";

    const baseFeeParts = worldInfo.base_fee.split(" ");
    const baseFeeValue = parseFloat(baseFeeParts[0] || "0");

    let fee = 0;

    selectedNfts.forEach((assetId) => {
      const nft = loginInfo.assets?.find(
        (asset: any) => asset.asset_id === assetId
      );

      if (!nft) return;

      const costMulti = nft.deer_config?.cost_multi || 1;
      fee += baseFeeValue * costMulti;
    });

    return fee.toFixed(4);
  }, [selectedNfts, loginInfo.assets, gameStatus?.reward_info, currentWorld]);

  // Calculate available deer count
  const availableDeerCount = useMemo(() => {
    return filteredNfts.filter(isExplorable).length;
  }, [filteredNfts, isExplorable]);

  // Add this handler function
  const handleSliderChange = useCallback(
    (event: Event, newValue: number | number[]) => {
      const value = newValue as number;
      setSliderValue(value);

      // Select top N explorable NFTs
      setSelectedNfts(() => {
        const newSelected = new Set<string>();
        let count = 0;

        for (const nft of filteredNfts) {
          if (isExplorable(nft) && count < value) {
            newSelected.add(nft.asset_id);
            count++;
          }
        }

        return newSelected;
      });
    },
    [filteredNfts, isExplorable]
  );

  return (
    <>
      <Paper
        elevation={3}
        sx={{
          backgroundColor: "#070508db",
          width: { xs: "95%", md: "90%" },
          margin: "auto",
          mt: 2,
          padding: { xs: "16px", sm: "20px", md: "30px" },
          borderRadius: "12px",
          color: "white",
        }}
      >
        {currentWorld ? (
          <Typography variant="h5" color="aquamarine" align="center" mb={2}>
            World {currentWorld} Exploration
          </Typography>
        ) : (
          <Typography variant="h5" color="orange" align="center" mb={2}>
            Please select a world first
          </Typography>
        )}
        <Typography
          variant="h6"
          sx={{
            color: "lightgreen",
            mb: 2,
            fontWeight: 500,
          }}
        >
          Select NFTs for exploration ({availableDeerCount} available)
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            flexWrap: "wrap",
            mb: 2,
            gap: 1,
          }}
        >
          {/* NFT Type Filter */}
          <Box
            sx={{
              backgroundColor: "#1e293b",
              borderRadius: "24px",
              padding: "4px",
              border: "1px solid #334155",
            }}
          >
            <ToggleButtonGroup
              color="primary"
              value={nftType}
              exclusive
              onChange={handleNftTypeChange}
              aria-label="NFT Type"
              size="small"
            >
              <ToggleButton value="ALL" aria-label="All NFT Types">
                All
              </ToggleButton>
              <ToggleButton value="fawn" aria-label="Fawn Type">
                Fawn
              </ToggleButton>
              <ToggleButton value="abyssal" aria-label="Abyssal Type">
                Abyssal
              </ToggleButton>
              <ToggleButton value="og" aria-label="OG Type">
                OG
              </ToggleButton>
              <ToggleButton value="promo" aria-label="Promo Type">
                Promo
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>

          {/* Show Image Switch (only for Abyssal type) */}
          {nftType === "abyssal" && (
            <FormGroup>
              <FormControlLabel
                sx={{ color: "white", margin: 0 }}
                control={
                  <Switch
                    checked={showImage}
                    onChange={handleSwitchChange}
                    color="secondary"
                    size="small"
                  />
                }
                label="Show Image"
              />
            </FormGroup>
          )}

          {/* Select All Button */}
          <Button
            variant="contained"
            color="primary"
            onClick={handleSelectAll}
            size="small"
            disabled={filteredNfts.length === 0 || currentWorld === ""}
          >
            {selectedNfts.size === filteredNfts.length ||
            selectedNfts.size === MAX_SELECT_COUNT
              ? "Deselect All"
              : `Select All (${Math.min(
                  filteredNfts.filter((item: any) => isExplorable(item)).length,
                  MAX_SELECT_COUNT
                )})`}
          </Button>
        </Box>
        <Box
          sx={{
            mb: 3,
            px: 2,
            py: 1,
            bgcolor: "rgba(30, 41, 59, 0.7)",
            borderRadius: 2,
            border: "1px solid #334155",
          }}
        >
          <Typography variant="body2" color="lightblue" gutterBottom>
            Quick Select: {sliderValue} Deers
          </Typography>
          <Slider
            value={sliderValue}
            onChange={handleSliderChange}
            min={0}
            max={Math.min(availableDeerCount, MAX_SELECT_COUNT)}
            valueLabelDisplay="auto"
            aria-label="Select number of NFTs"
            marks={[
              { value: 0, label: "0" },
              {
                value: Math.min(availableDeerCount, MAX_SELECT_COUNT),
                label: Math.min(
                  availableDeerCount,
                  MAX_SELECT_COUNT
                ).toString(),
              },
            ]}
            sx={{
              color: "aquamarine",
              "& .MuiSlider-thumb": {
                backgroundColor: "#fff",
              },
              "& .MuiSlider-track": {
                backgroundColor: "lightgreen",
              },
              "& .MuiSlider-rail": {
                backgroundColor: "#334155",
              },
              "& .MuiSlider-mark": {
                backgroundColor: "#fff",
              },
              "& .MuiSlider-markLabel": {
                color: "#fff",
              },
            }}
          />
        </Box>{" "}
        {/* Exploration Controls */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
            gap: 2,
          }}
        >
          <Typography variant="body1" sx={{ color: "white" }}>
            {selectedNfts.size} {selectedNfts.size === 1 ? "Deer" : "Deers"}{" "}
            Selected
          </Typography>
          {/* Exploration Fee and Control Section */}
          <Box
            sx={{
              bgcolor: "#1e293b",
              borderRadius: "8px",
              p: 2,
              border: "1px solid #334155",
            }}
          >
            {/* Fee information */}
            {currentWorld && (
              <>
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: "yellow",
                    textAlign: "center",
                    mb: 1,
                  }}
                >
                  Fee Required for Exploration
                </Typography>

                <Typography
                  variant="h6"
                  sx={{
                    color: "white",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  {totalFee} GRASS
                </Typography>
              </>
            )}
          </Box>

          <Button
            color="primary"
            variant="contained"
            onClick={doExploration}
            disabled={selectedNfts.size === 0 || !currentWorld}
          >
            Explore World {currentWorld}
          </Button>
        </Box>
        <Divider sx={{ borderColor: "#334155", mb: 2 }} />
        {/* NFTs Grid */}
        <Box
          sx={{
            maxHeight: "60vh",
            overflowY: "auto",
            border: "1px solid #334155",
            borderRadius: "8px",
            bgcolor: "rgba(0,0,0,0.2)",
            mb: 3,
          }}
        >
          {filteredNfts.length > 0 ? (
            <Grid container spacing={2} sx={{ p: 2 }}>
              {filteredNfts.map((item: any) => (
                <DeerCard
                  key={item.asset_id}
                  uri={
                    URI_PREFIX +
                    (nftType === "abyssal" && !showImage
                      ? ABYSSAL_TEMP
                      : item.data.img)
                  }
                  name={item.data.name}
                  assetID={item.asset_id}
                  stakedTime={item.exploration_time}
                  isSelected={selectedNfts.has(item.asset_id)}
                  onClick={handleNFTClick}
                  nftData={item}
                />
              ))}
            </Grid>
          ) : (
            <Box sx={{ p: 4, textAlign: "center" }}>
              <Typography variant="body1" color="text.secondary">
                No matching deer NFTs found. Try changing the filter.
              </Typography>
            </Box>
          )}
        </Box>
      </Paper>

      <ToastContainer position="top-right" />
    </>
  );
};
