import { Box, Button, Grid, Slider, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import InventoryItemCard from "../components/InventoryItemCard";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../store";
import { MODE } from "../constant";
import NFTCard from "../components/NFTCard";
import { TournamentCardView } from "../components/TournamentCardView";
import { TournamentListView } from "../components/TournamentListView";
import { TournamentResultView } from "../components/TournamentResultView";
import { GetTournamentList } from "../store/user/actions";

// let uri_prefix = "https://ipfs.neftyblocks.io/ipfs/";
let uri_prefix = "https://ipfs.io/ipfs/";

export const Tournament = () => {
  const dispatch = useDispatch<any>();
  const [tournamentId, setTournamentId] = useState(0);

  useEffect(() => {
    dispatch(GetTournamentList());
  }, []);
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundImage: "url(/images/tournament_bg.png)",
      }}
    >
      <Box
        sx={{
          marginTop: "64px",
          height: "calc(100vh - 64px)",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            p: { xs: 1, sm: 1, md: 10 },
            boxSizing: "border-box",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* <h1 style={{ color: "#2203c1" }}>TOURNAMENT</h1> */}
          <h1 style={{ color: "#2203c1", position: "relative" }}>
            <span
              style={{
                position: "relative",
                zIndex: "100",
                color: "white",
                fontSize: "24px",
              }}
            >
              TOURNAMENT
            </span>
            <img
              src="/images/title_bg.png"
              style={{
                position: "absolute",
                top: 0,
                left: "50%",
                width: "200px",
                transform: "translate(-50%, -4px)",
              }}
            />
          </h1>
          {tournamentId != 0 && (
            <Box sx={{ textAlign: "left", width: "100%" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setTournamentId(0);
                }}
              >
                Back
              </Button>
            </Box>
          )}
          {tournamentId == 0 && <TournamentListView setId={setTournamentId} />}
          {tournamentId > 0 && <TournamentCardView id={tournamentId} />}
          {tournamentId < 0 && <TournamentResultView id={tournamentId} />}
          <Box></Box>
        </Box>
      </Box>
    </Box>
  );
};
